import * as React from "react";
import Moment from "moment/moment";
import { IPayScreenViewModel } from "../../../core/domain/viewModels/IPayScreenViewModel";
import { ITaxingAuthority } from "../../../core/domain/models/ITaxingAuthority";
import { IDocumentNotificationSetting } from "../../../core/domain/models/IDocumentSettings";
import { IUtilities } from "../../../core/utilities/Utilities";
import {
  IVoucher,
  VoucherTypes,
  DocumentGroups,
  CustomType,
  VoucherMode,
  VoucherStatus,
} from "../../../core/domain/models/IGroup";
import { container } from "../../../startup/inversify.config";
import { TYPES } from "../../../startup/types";
import {
  CustomButton,
  CustomButtonPlacement,
} from "../../Common/CustomButton/CustomButton";
import {
  Common,
  DownloadDocumentsConstants,
  InvoicePaymentConstants,
} from "../../Common/Constants";
import { DropdownComponent } from "../../Common/DropdownComponent";
import { ISelectItem } from "../../../core/domain/viewModels/ICommon";
import { ITaxReturn } from "../../../core/domain/models/ITaxReturn";
import { PaymentInformationPopup } from "../PaymentInformationPopup";
import {
  AuthorityImage,
  StateUSA,
  StateImages,
} from "../../Common/Icons/StateImages";
import { SvgIconPayTaxPrep } from "../../Common/Icons/SvgIcons";
import { getDueDateBasedVouchers } from "../PayHelper";
import { VoucherPaymentInfoPopup } from "../VoucherPaymentInfoPopup";
import { MarsNotifier } from "../../Common/Notification/MarsNotifier";
import { ITaxpayerPaymentSettings } from "../../../core/domain/models/company/Company";
const utilities = container.get<IUtilities>(TYPES.IUtilities);
import { VoucherPayConstant } from "../../Common/Constants";
import TaxpayerHelper from "../../Helper/TaxpayerHelper";
import CustomDropdown, {
  ICustomDropdownOption,
} from "../../Common/CustomDropDown/CustomDropdown";
import {
  PaymentVoucherDownloadIcon,
  FilingInstructionDownloadIcon,
  PaymentVoucherPaidIcon,
  VoucherPaidIndicatorIcon,
  ExclamationIcon,
} from "../../Common/Icons/SvgIcons";
import { ButtonComponent } from "cp-common-ui-components";
import { handleKeyDown } from "../../../components/Helper/HelperFunction";

export interface VoucherDetailsProps {
  payScreenData: IPayScreenViewModel;
  documentNotificationSetting: IDocumentNotificationSetting;
  taxingAuthorities: ITaxingAuthority[];
  selectedDate: string;
  selectedPaymentType: string;
  taxReturn: ITaxReturn;
  clientId: string;
  downloadAllVouchers: (clientId: string) => void;
  downloadFilingInstructions: (clientId: string) => void;
  downloadSingleVoucher: (
    clientId: string,
    fileName: string,
    pageNo: string,
    bookMarks: string
  ) => void;
  downloadTaxInvoice: (clientId: string) => void;
  HandleVoucherReminderEnable: (value: boolean) => void;
  HandleVoucherReminderDays: (value: number) => void;
  invoicePaymentUrl?: string;
  saveVoucherPayment: (voucher: IVoucher) => void;
  isPreviewMode: boolean;
  clearPaymentDetails: (voucher: IVoucher) => void;
  taxpayerPaymentSettings: ITaxpayerPaymentSettings;
  documentUpdated?: boolean;
  SetDocumentIsDownloaded?: (clientId: string) => void;
}

interface VoucherDetailsState {
  selectedVouchers: IVoucher[];
  showInitialMessage: boolean;
  IsVoucherReminderEnable: boolean;
  reminderDays: ICustomDropdownOption[];
  showPaymentInfoPopup: boolean;
  taxingAuthority: ITaxingAuthority;
  voucher: IVoucher;
  voucherReminderDay: ISelectItem;
  showVoucherDiv: boolean;
  isInvoicePopup: boolean;
  showVoucherPaidInfo: boolean;
}
export const Constants = {
  ReminderDays: 30,
  VoucherRemTxt1: "Email me reminder",
  VoucherRemTxt2: "days before payment is due.",
};

class EstimatedVoucherDetails extends React.Component<
  VoucherDetailsProps,
  VoucherDetailsState
> {
  constructor(props: VoucherDetailsProps) {
    super(props);
    this.state = {
      selectedVouchers: [],
      showInitialMessage: true,
      IsVoucherReminderEnable: false,
      reminderDays: [],
      voucherReminderDay: { key: "", value: "" },
      showPaymentInfoPopup: false,
      voucher: {
        amount: 0,
        authorityID: 0,
        authorityImage: "",
        authorityName: "",
        bookmark: "",
        customText: "",
        customType: 0,
        dueDate: new Date(),
        formName: "",
        groupId: 0,
        isMasterPaymentUrl: false,
        onlinePaymentUri: "",
        pageNo: [],
        paymentType: 0,
        vocherMode: 0,
        voucherNo: 0,
        amountPaid: 0,
        checkNumber: "",
        paidDate: new Date(),
        isPaid: false,
      },
      taxingAuthority: {
        Id: 0,
        AuthorityName: "",
        isEsignEnabled: false,
        Abbreviation: "",
        PaymentURL: "",
        PrefixStateCode: false,
        Active: false,
        CustomAuthority: false,
        StateImage: "",
        InUse: 0,
      },
      showVoucherDiv: false,
      isInvoicePopup: false,
      showVoucherPaidInfo: false,
    };
  }

  componentDidMount() {
    this.fillReminderDropDown();
  }

  fillReminderDropDown() {
    let days: ICustomDropdownOption[] = [];
    for (var i = 1; i <= Constants.ReminderDays; i++) {
      let strNum = i.toString();
      days.push({ label: strNum, value: parseInt(strNum) });
    }
    this.setState({ reminderDays: days });
  }

  componentWillReceiveProps(nextProps: VoucherDetailsProps) {
    if (
      nextProps.selectedPaymentType !== "None" &&
      nextProps.selectedPaymentType !== ""
    ) {
      const groupedVouchers = getDueDateBasedVouchers(
        nextProps.payScreenData.vouchers
      );

      const selectedVouchers = groupedVouchers
        .find((m) => m.groupName === nextProps.selectedDate)!
        .vouchers.filter(
          (n) =>
            n.paymentType ===
            VoucherTypes[
              nextProps.selectedPaymentType as keyof typeof VoucherTypes
            ]
        )
        .sort(function (a, b) {
          return a.authorityID - b.authorityID;
        });

      this.setState({
        showInitialMessage: false,
        selectedVouchers: selectedVouchers,
        isInvoicePopup: false,
      });
    } else if (nextProps.selectedPaymentType === "None") {
      this.setState({
        showInitialMessage: false,
        isInvoicePopup: true,
      });
    }
    if (
      nextProps.documentNotificationSetting &&
      nextProps.documentNotificationSetting
        .paymentVoucherNotificationSettingsModel
    ) {
      const { enablePaymentVoucherReminder, noOfDaysForVoucherReminder } =
        nextProps.documentNotificationSetting
          .paymentVoucherNotificationSettingsModel;
      this.setState({
        IsVoucherReminderEnable: TaxpayerHelper.isVoucherReminderDisabled(this.props.payScreenData)
          ? false
          : enablePaymentVoucherReminder,
        voucherReminderDay: {
          key: noOfDaysForVoucherReminder.toString(),
          value: noOfDaysForVoucherReminder.toString(),
        },
        showVoucherDiv: enablePaymentVoucherReminder,
      });
    }
  } 

  private onReminderEnable = (e: any) => {
    const isChecked = e.target.checked;
    this.props.HandleVoucherReminderEnable(isChecked);
    this.setState({ IsVoucherReminderEnable: isChecked });
  };

  private enableReminderOnKeyDown = (reminderEnabled: boolean) => {
    this.props.HandleVoucherReminderEnable(reminderEnabled);
    this.setState({ IsVoucherReminderEnable: reminderEnabled });
  };

  private onPayClick = (taxAuthority: ITaxingAuthority, vouch: IVoucher) => {
    this.setState({
      showPaymentInfoPopup: true,
      voucher: vouch,
      taxingAuthority: taxAuthority,
    });
  };

  private onInvoicePayClick = () => {
    this.setState({ showPaymentInfoPopup: true });
  };

  private onPaymentInfoCancel = () => {
    this.setState({
      showPaymentInfoPopup: false,
    });
  };

  private addVoucherPayement = (
    taxAuthority: ITaxingAuthority,
    voucher: IVoucher
  ) => {
    this.setState({
      showVoucherPaidInfo: true,
      voucher: voucher,
      taxingAuthority: taxAuthority,
    });
  };

  addVoucherPayementCancel = () => {
    this.setState({
      showVoucherPaidInfo: false,
    });
  };

  onDownloadAllVouchers = () => {
    if (!this.props.isPreviewMode) {
      this.props.downloadAllVouchers(this.props.clientId);
    } else {
      MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
    }
  };

  onDownloadFilingInstructions = () => {
    if (!this.props.isPreviewMode) {
      this.props.downloadFilingInstructions(this.props.clientId);
      this.props.SetDocumentIsDownloaded &&
        this.props.documentUpdated &&
        TaxpayerHelper.isTransmittalUpdated(this.props.taxReturn) &&
        this.props.SetDocumentIsDownloaded(this.props.clientId);
    } else {
      MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
    }
  };

  onDownloadIndividualVoucher = (
    clientId: string,
    fileName: string,
    pageNo: string,
    bookMarks: string
  ) => {
    if (!this.props.isPreviewMode) {
      this.props.downloadSingleVoucher(clientId, fileName, pageNo, bookMarks);
    } else {
      MarsNotifier.Warning(DownloadDocumentsConstants.DownloadNotAllowed, null);
    }
  };

  getVoucherStatus = (voucherState: any) => {
    if (voucherState !== undefined) {
      if (voucherState === VoucherStatus.New) {
        return (
          <>
            <div className="voucher-status-info">New</div>
          </>
        );
      } else if (voucherState === VoucherStatus.ExistingUpdated) {
        return (
          <>
            <div className="voucher-status-info" style={{ width: "65px" }}>
              Update
            </div>
          </>
        );
      }
    }
  };

  getVoucherHeaderClass = (voucher: IVoucher, expiredVoucher: boolean) => {
    if (voucher.isPaid) {
      return "card-header card-header-success";
    } else if (expiredVoucher) {
      return "card-header card-header-warning";
    } else {
      return "card-header card-header-default";
    }
  };

  getVoucherStatusIcon = (voucher: IVoucher, expiredVoucher: boolean) => {
    if (voucher.isPaid) {
      return (
        <span className="icon-voucher-paid">
          <PaymentVoucherPaidIcon />
        </span>
      );
    } else if (expiredVoucher) {
      return (
        <span className="icon-voucher-paid">
          <ExclamationIcon />
        </span>
      );
    } else {
      return "";
    }
  };

  private onReminderDaysChange = (option: ICustomDropdownOption | null) => {
    let val: number = option?.value ?? 0;
    if (val === undefined || val === null) {
      val = 0;
    }
    if (!isNaN(val)) {
      this.props.HandleVoucherReminderDays(val);

      this.setState({
        voucherReminderDay: {
          key: val.toString(),
          value: val.toString(),
        },
      });
    }
  };

  setSelectedDay = (): ICustomDropdownOption | null => {
    return {
      label: this.state.voucherReminderDay.key,
      value: parseInt(this.state.voucherReminderDay.value),
    };
  };

  getReminderDays() {
    let days: ICustomDropdownOption[] = [];
    for (var i = 1; i <= Constants.ReminderDays; i++) {
      let strNum = i.toString();
      days.push({ label: strNum, value: parseInt(strNum) });
    }
    return days;
  }

  public render() {
    console.log(this.state.voucher);
    return (
      <>
        <div className="row no-padding voucher-reminderme-container">
          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 no-padding">
            {
              <div className="checkbox-inline voucher-dropdown">
                <input
                  type="checkbox"
                  id="chkReminder"
                  tabIndex={0}
                  checked={this.state.IsVoucherReminderEnable}
                  className="voucher-checkbox"
                  onChange={this.onReminderEnable}
                  data-test-auto="317C2922-D91A-41DA-9072-470F39E36D4E"
				  disabled={TaxpayerHelper.isVoucherReminderDisabled(this.props.payScreenData)}
                  onKeyDown={(e: any) =>
                    handleKeyDown(e, () =>
                      this.enableReminderOnKeyDown(
                        !this.state.IsVoucherReminderEnable
                      )
                    )
                  }
                />
                <div className="voucher-reminderdays-container">
                  <label htmlFor="chkReminder">
                    {Constants.VoucherRemTxt1}
                  </label>
                  <CustomDropdown
                    id="drpEstimatedVoucherReminderDays"
                    selectedOption={this.setSelectedDay()}
                    setSelectedOption={this.onReminderDaysChange}
                    options={this.state.reminderDays}
                    customPlaceHolder="Days"
                    clearable={false}
                    searchable={false}
                    height={29}
                    disabled={!this.state.IsVoucherReminderEnable}
                    tabIndex="0"
                  />
                  <span className="pl-2">{Constants.VoucherRemTxt2}</span>
                </div>
              </div>
            }
          </div>

          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 payment-download-options">
            <span style={{ flex: 1 }}></span>
            <ButtonComponent
              data-test-auto="A7E39EDC-22DF-4392-B20D-A7D595A4AC50"
              onClick={this.onDownloadAllVouchers}
              variant={"outline-primary"}
              size={"medium"}
              buttonClassName={"btn-payment-voucherdownload"}
            >
              <PaymentVoucherDownloadIcon />
              <span className="pl-2">Payment Voucher</span>
            </ButtonComponent>
            <ButtonComponent
              data-test-auto="CB0CC5B1-16D4-48B4-BB13-95FE4B323DD4"
              onClick={() => {
                if (
                  this.props.taxReturn &&
                  TaxpayerHelper.anyTransmittals(this.props.taxReturn)
                ) {
                  this.onDownloadFilingInstructions();
                }
              }}
              variant={"outline-primary"}
              size={"medium"}
              buttonClassName={
                this.props.taxReturn &&
                TaxpayerHelper.anyTransmittals(this.props.taxReturn)
                  ? "btn-payment-voucherdownload"
                  : " btn-payment-voucherdownload opacityPoint4"
              }
            >
              <FilingInstructionDownloadIcon />
              <span className="pl-2">Filing Instructions</span>
              {this.props.taxReturn &&
              (!TaxpayerHelper.anyTransmittals(this.props.taxReturn) ||
                (this.props.documentUpdated &&
                  TaxpayerHelper.isTransmittalUpdated(
                    this.props.taxReturn
                  ))) ? (
                <span className="btn-tooltip">
                  {this.props.taxReturn &&
                    (!TaxpayerHelper.anyTransmittals(this.props.taxReturn)
                      ? VoucherPayConstant.NoTransmittals
                      : this.props.documentUpdated &&
                        TaxpayerHelper.isTransmittalUpdated(
                          this.props.taxReturn
                        )
                      ? VoucherPayConstant.UpdatedDocumentAvailable
                      : undefined)}
                </span>
              ) : (
                ""
              )}
            </ButtonComponent>
          </div>
        </div>
        <div className="payment-card-container">
          {this.props.taxReturn.formGroups.find(
            (m) => m.group == DocumentGroups.Vouchers
          )?.forms?.length === 0 && this.props.taxReturn.invoiceAmount === 0 ? (
            <React.Fragment>
              <div style={{ marginTop: "1%" }}>
                <h6 className="section-heading">No Payment Vouchers</h6>
              </div>
            </React.Fragment>
          ) : this.state.showInitialMessage ? (
            //selecting first estimated voucher so no need of this component
            <React.Fragment>
              {/*this.props.payScreenData.documentId === 0 ? <Shimmer height={340} /> :
                                    <div>
                                        <Row>
                                            <Col lg={12} md={12} sm={12} xs={12}
                                                style={{ textAlign: 'center', padding: '3%' }}>
                                                <SvgIconTaxPaymentsSummary
                                                    width={100}
                                                    height={100}
                                                    data-test-auto="CAAB95C3-CC00-49F4-BCBB-76FBAA71F662"
                                                />
                                            </Col>
                                        </Row>
                                        <Row className="row width-100" >
                                            <div className="col-xs-12 col-sm-12 col-md-4 col-lg-2">
                                                <SvgIconLeftPointer
                                                    width={110}
                                                    className="svg-shadow" />
                                            </div>
                                            <div className="col-xs-12 col-sm-12 col-md-8 col-lg-10" style={{ textAlign: "center", paddingRight: "10%" }}>
                                                <h4 className="section-heading"> Select a Due Date from the left panel </h4>
                                                <h6 className="section-heading">(Once selected, you will be able to view tax vouchers and make payments)</h6>
                                            </div>
                                        </Row>
                                    </div>
                                */}
            </React.Fragment>
          ) : /* Voucher Info */
          this.props.selectedPaymentType !== "None" ? (
            this.state.selectedVouchers.map((item, index) => {
              let taxingAuthority: ITaxingAuthority =
                this.props.taxingAuthorities.find(
                  (m) => m.Id == item.authorityID
                ) as ITaxingAuthority;
              let stateAbbreviation: any = taxingAuthority?.Abbreviation;
              const authorityName =
                TaxpayerHelper.getAuthorityImageName(stateAbbreviation);
              const expiredVoucher =
                parseInt(Moment.utc(item.dueDate).format("YYYYMMDD")) <
                parseInt(Moment.utc(new Date()).format("YYYYMMDD"));
              return (
                <>
                  <div className="card pay-display-card" key={index}>
                    <div
                      className={this.getVoucherHeaderClass(
                        item,
                        expiredVoucher
                      )}
                    >
                      {item.isPaid ? "Paid" : "Due"}{" "}
                      {Moment.utc(item.dueDate).format("MM/DD/YY")}
                      {this.getVoucherStatusIcon(item, expiredVoucher)}
                      {item.isPaid
                        ? ""
                        : this.getVoucherStatus(item.voucherStatus)}
                    </div>
                    <div className="card-body">
                      {item.vocherMode === VoucherMode.Recognized &&
                        (item.customType ===
                          CustomType.DoNotPayScheduledAutomaticWithdrawal ||
                          item.customType == CustomType.AddCustom) && (
                          <div
                            data-test-auto="615A30F8-442F-4468-BC49-E22C63D1788C"
                            className="icon-individual-voucher"
                            onClick={() =>
                              this.onDownloadIndividualVoucher(
                                this.props.clientId,
                                "Voucher_" +
                                  taxingAuthority?.AuthorityName +
                                  "_" +
                                  Moment(item.dueDate)
                                    .format("MM-DD-YYYY")
                                    .toString(),
                                item.pageNo.toString(),
                                item.bookmark
                              )
                            }
                          >
                            <FilingInstructionDownloadIcon />
                          </div>
                        )}
                      <div className="pay-icon">
                        <AuthorityImage
                          authoriyName={authorityName || "state-federal"}
                        />
                      </div>

                      <div className="pay-card-info">
                        <div
                          className="pay-card-fee-name"
                          title={
                            this.props.taxingAuthorities.find(
                              (m) => m.Id == item.authorityID
                            )?.AuthorityName
                          }
                        >
                          {
                            this.props.taxingAuthorities.find(
                              (m) => m.Id == item.authorityID
                            )?.AuthorityName
                          }
                        </div>
                        <div className="pay-card-fee-amount">
                          {" "}
                          ${utilities.formatCurrencyText(item.amount)}
                        </div>
                      </div>

                      {item.customType ===
                      CustomType.DoNotPayScheduledAutomaticWithdrawal ? (
                        <div className="donotpay-vouchertype-text">
                          <span title="DO NOT PAY : Scheduled for automatic withdrawal">
                            <b> DO NOT PAY :</b> Scheduled for automatic
                            withdrawal
                          </span>
                        </div>
                      ) : item.isPaid ? (
                        <div
                          data-test-auto="36BEFCEE-3FDA-4F4F-8F59-A98BCC39A438"
                          style={{ paddingBottom: "10px" }}
                        >
                          <VoucherPaidIndicatorIcon />
                          <span className="payment-paid-indicator">PAID</span>
                        </div>
                      ) : (
                        <div className="pay-button-container">
                          {item.customType ===
                          CustomType.OnlinePaymentRequired ? (
                            <ButtonComponent
                              data-test-auto="4C8EF285-E158-4430-99E7-EEFBB38E9396"
                              onClick={() =>
                                this.onPayClick(taxingAuthority, item)
                              }
                              variant={"primary"}
                              size={"medium"}
                            >
                              Pay Online
                            </ButtonComponent>
                          ) : item.customType === CustomType.AddCustom ? (
                            <div
                              title={item.customText}
                              className="vouchertype-custom-text"
                            >
                              {item.customText}
                            </div>
                          ) : (
                            (item.customType === CustomType.None ||
                              item.customType === CustomType.Select) && (
                              <ButtonComponent
                                data-test-auto="75A98C85-7EAB-483B-B3AA-80816B17A8AA"
                                onClick={() => {
                                  this.onPayClick(taxingAuthority, item);
                                }}
                                variant={"primary"}
                                size={"medium"}
                              >
                                Pay
                              </ButtonComponent>
                            )
                          )}
                        </div>
                      )}

                      {item.customType !==
                      CustomType.DoNotPayScheduledAutomaticWithdrawal ? (
                        <div className="voucherPayementLinkContainer">
                          {!item.isPaid ? (
                            <span>
                              <a
                                data-test-auto="810437EC-C36F-464D-A9BA-8565E8F365A4"
                                className="voucherPayementLink"
                                onClick={() =>
                                  this.addVoucherPayement(taxingAuthority, item)
                                }
                                tabIndex={0}
                                onKeyDown={(e: any) =>
                                  handleKeyDown(e, () =>
                                    this.addVoucherPayement(
                                      taxingAuthority,
                                      item
                                    )
                                  )
                                }
                              >
                                Add Payment Details
                              </a>
                            </span>
                          ) : (
                            <span>
                              <a
                                data-test-auto="0FD0EEEE-3F9C-4943-A1B3-EFE05EC854E0"
                                className="voucherPayementLink"
                                onClick={() =>
                                  this.addVoucherPayement(taxingAuthority, item)
                                }
                                tabIndex={0}
                                onKeyDown={(e: any) =>
                                  handleKeyDown(e, () =>
                                    this.addVoucherPayement(
                                      taxingAuthority,
                                      item
                                    )
                                  )
                                }
                              >
                                Edit Payment Details
                              </a>
                            </span>
                          )}
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </>
              );
            })
          ) : (
            /* Invoice Info*/
            <div className="card pay-display-card">
              <div className="card-header card-header-warning invoice-pay-header-text">
                {InvoicePaymentConstants.PaymentDueCaption}
              </div>
              <div className="card-body">
                <div className="pay-icon invoice-icon">
                  <SvgIconPayTaxPrep />
                </div>
                <div className="pay-card-info">
                  <div className="pay-card-fee-name">
                    {" "}
                    {InvoicePaymentConstants.TaxPreparationFeeText}{" "}
                  </div>
                  <div className="pay-card-fee-amount">
                    {" "}
                    $
                    {utilities.formatCurrencyText(
                      this.props.taxReturn.invoiceAmount
                    )}
                  </div>
                  {this.props.taxReturn.invoiceAmount > 0 && (
                    <div className="pay-button-container">
                      <ButtonComponent
                        id="btnPayInvoice"
                        data-test-auto="8A84AD59-D560-4FBE-84F2-65314EEE6469"
                        onClick={this.onInvoicePayClick}
                        hidden={false}
                        variant={"primary"}
                        size={"medium"}
                      >
                        Pay
                      </ButtonComponent>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>

        <PaymentInformationPopup
          show={this.state.showPaymentInfoPopup}
          downloadFilingInstructions={this.props.downloadFilingInstructions}
          taxingAuthority={this.state.taxingAuthority}
          voucher={this.state.voucher}
          onCancel={this.onPaymentInfoCancel}
          clientId={this.props.clientId}
          isInvoicePopup={this.state.isInvoicePopup}
          invoiceAmount={this.props.taxReturn.invoiceAmount}
          downloadSingleVoucher={this.props.downloadSingleVoucher}
          downloadTaxInvoice={this.props.downloadTaxInvoice}
          invoicePaymentUrl={this.props.invoicePaymentUrl}
          isPreviewMode={this.props.isPreviewMode}
          taxpayerPaymentSettings={this.props.taxpayerPaymentSettings}
          documentUpdated={this.props.documentUpdated}
          SetDocumentIsDownloaded={this.props.SetDocumentIsDownloaded}
          taxReturn={this.props.taxReturn}
        />

        <VoucherPaymentInfoPopup
          show={this.state.showVoucherPaidInfo}
          onCancel={this.addVoucherPayementCancel}
          taxingAuthority={this.state.taxingAuthority}
          voucher={this.state.voucher}
          saveVoucherPayment={this.props.saveVoucherPayment}
          clearPaymentDetails={this.props.clearPaymentDetails}
        />
      </>
    );
  }
}

export default EstimatedVoucherDetails;
