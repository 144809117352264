import { ApplicationState } from "../../store/index";
import { actionCreators as SummaryStore } from '../../store/SummaryStore';
import { actionCreators as HelperStore } from '../../store/Common/HelperStore';
import { actionCreators as EsignStore } from '../../store/Common/EsignStore';
import { PreviewComponent } from "./PreviewComponent";
import { actionCreators as PreparerMessageStore } from '../../store/PreparerMessageStore';
import { connect } from 'react-redux';
import { createLoadingSelector } from '../../store/selectors';
import { actionCreators as DocumentReviewStore } from '../../store/ReviewStore';
import { actionCreators as TaxDocumentStore } from '../../store/Common/TaxDocumentStore';
import { actionCreators as ManualSignStore } from '../../store/Sign/ManualSignStore';
import { actionCreators as CompanyStore } from '../../store/Common/Company/CompanyStore';
import { EngagementTypeS } from "../../core/common/Enums";
import { actionCreators as K1DistributeStore } from '../../store/K1/K1DistributeStore';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { actionCreators as PaperFileReturnStore } from '../../store/PaperFileReturn/PaperFileReturnStore';
import { actionCreators as SignProcessStore } from '../../store/SignProcess/SignProcessStore';
import { actionCreators as ReviewStore } from '../../store/ReviewStore';
import { actionCreators as PayStore } from '../../store/PayStore';
import { actionCreators as DownloadableDocumentsStore } from '../../store/DownloadableDocumentsStore';
import { IPaymentVoucherNotificationSettings } from "../../core/domain/models/IDocumentSettings";
import { IClientProcessViewModel } from '../../core/domain/viewModels/IClientProcessViewModel'
import { actionCreators as TutorialStore } from '../../store/Tutorial/TutorialStore';
import { IVoucher } from '../../core/domain/models/IGroup';
import { actionCreators as PaymentStore } from '../../store/PaymentStore'; 
import { IPaperFileReturnDetails } from '../../core/domain/models/IPaperFileReturn';
import { actionCreators as DocumentStatusStore } from '../../store/Common/DocumentStatusStore';
import { actionCreators as TrialK1DistributeStore } from '../../store/K1/TrialK1DistributeStore';

const loadingSelector = createLoadingSelector(['HEADERINFO', 'SUMMARY_DETAILS',
    'TAXING_AUTHORITY', 'DOCUMENT_REVIEW_MODEL', 'DOCUMENT_SETTINGS']);

function mapStateToProps(state: ApplicationState) {
    return {
        summaryData: state.summaryData,
        esignData: state.esignData.data,
        signerData: state.esignData.signerDetails,
        manualSignData: state.manualSignData.data,
        companyData: state.companyData.data,
        signatureControlsData: state.esignData.controlsData,
        payScreenData: state.payScreenData,
        loading: loadingSelector(state),
        commonData: state.commonData,
        signatureSettingModel: state.signatureSettingData,
        documentSettings: state.documentSettings,
        documentReviewModel: state.documentReviewModel,
        headInfoViewModel: state.headerInfo,
        taxReturn: state.taxReturn.taxDocument,
        k1Data: state.k1Data.data,
        paperFileReturnData: state.paperFileReturnData.data,
        previewReady: state.previewDocument.previewReady,
		signProcessInfo: state.signProcessData.clientprocessmodel.currentstep,
		loggedInUserInfo: state.headerInfo.loggedInUserInfo,
		tutorialInfo: state.tutorialInfo,
        additionalEsignDocuments: state.downloadableDocuments.additionalEsignDocuments,	
        paymentData: state.paymentData.data,
        changedSignBehalfSpouse: state.esignData.changedSignBehalfSpouse,
        DocumentStatusState: state.DocumentStatusState,
        isK1Loading: state.k1Data.isLoading
    };
}

const mapDispatchToProps = (dispatch: any,state :ApplicationState) => {
    return {
        requestSummaryDetails: (clientId: string) => dispatch(SummaryStore.requestSummaryDetails(clientId)),
        requestAllTaxingAuthorities: (id: string) => dispatch(HelperStore.requestAllTaxingAuthorities(id)),
        clientAuthentication: (clientGuid: string, failureCallback: () => void) => dispatch(EsignStore.clientAuthentication(clientGuid, failureCallback)),
        requestPreviewSignatureControls: (clientGuid: string) => dispatch(EsignStore.requestPreviewSignatureControls(clientGuid)),
        requestSignBehalfSpousePreviewSignatureControls: (clientGuid: string) => dispatch(EsignStore.requestSignBehalfSpousePreviewSignatureControls(clientGuid)),
        updateSignatureControlsData: (data: any) => dispatch(EsignStore.updateSignatureControlsData(data)),
        downloadAllAttachments: (clientId: string, attachments: any) => dispatch(DocumentReviewStore.downloadAllAttachments(clientId, attachments)),
        downloadIndividualAttachment: (clientId: string, attachment: any) => dispatch(DocumentReviewStore.downloadIndividualAttachment(clientId, attachment)),
        requestDocumentsForPreview: (clientId: string, callback?: () => void) => dispatch(DocumentReviewStore.requestDocumentsForPreview(clientId, callback)),
        requestDocumentSettings: (clientId: string) => dispatch(PreparerMessageStore.requestDocumentSettings(clientId)),
        requestTaxDocument: (clientGuid: string) => dispatch(TaxDocumentStore.requestTaxDocument(clientGuid)),
        requestSigners: (clientGuid: string) => dispatch(EsignStore.requestSigners(clientGuid)),
        requestSignedDocuments: (clientGuid: string, forceRefresh?: boolean) => dispatch(ManualSignStore.requestSignedDocuments(clientGuid, forceRefresh)),
        requestCompanyDetails: (clientGuid: string, forceRefresh?: boolean) => dispatch(CompanyStore.requestCompanyDetails(clientGuid, forceRefresh)),
        requestK1ShareHolderDetails: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string, searchText: string, successCallback?:()=>void) => dispatch(K1DistributeStore.requestK1ShareHolderDetails(clientGuid, pageNo, pageSize, sortBy, sortOrder, searchText, successCallback)),
        downloadK1DocumentAsync: (clientId: string, engagementTpe: EngagementTypeS, callback?:()=>void) => dispatch(K1DistributeStore.downloadK1DocumentForPreviewAsync(clientId, engagementTpe, callback)),
        downloadIndividualK1DocumentAsync: (clientId: string, shareHolder: IK1ShareHolderDetails, engagementTpe: EngagementTypeS) => dispatch(K1DistributeStore.downloadIndividualK1DocumentForPreviewAsync(clientId, shareHolder, engagementTpe)),
        downloadSelectedK1DocumentAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS, callback?: () => void) => dispatch(K1DistributeStore.downloadSelectedK1DocumentForPreviewAsync(clientId, shareHolders, engagementTpe, callback)),
        requestDocumentPreviewStatus: (clientGuid: string, step: number, callback: (step?: number) => void) => dispatch(ReviewStore.requestDocumentPreviewStatus(clientGuid, step, callback)),
        downloadEfileForms: (clientGuid: string, successCallback?: () => void) => dispatch(ManualSignStore.downloadEfileForms(clientGuid, successCallback)),
        downloadPreviewEfileForms: (clientGuid: string, successCallback?: () => void) => dispatch(ManualSignStore.downloadPreviewEfileForms(clientGuid, successCallback)),
        requestPayScreenDetails: (clientId: string) => dispatch(PayStore.requestPayScreenDetails(clientId)),
        downloadAllVouchers: (clientId: string) => dispatch(PayStore.downloadAllVouchers(clientId)),
        downloadFilingInstructions: (clientId: string) => dispatch(PayStore.downloadFilingInstructions(clientId)),
        downloadTaxInvoice: (clientId: string) => dispatch(PayStore.downloadTaxInvoice(clientId)),
        updateVoucherNotificationSettings: (clientId: string, setting: IPaymentVoucherNotificationSettings, callback: () => void) => dispatch(PayStore.updateVoucherNotificationSettings(clientId, setting, callback)),
        downloadSingleVoucher: (clientId: string, fileName: string, pageNo: string, bookMarks: string) => dispatch(PayStore.downloadSingleVoucher(clientId, fileName, pageNo, bookMarks)),
        requestLastVisitedStep: (clientGuid: string, successCallback?: (lastVisitedStep: number) => void) => dispatch(SignProcessStore.requestLastVisitedStep(clientGuid, successCallback)),
        updateK1ShareHolderEmail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, successCallback?: () => void) =>  dispatch(K1DistributeStore.updateK1ShareHolderEmail(clientGuid, k1Partner, successCallback)),

        updateK1ShareHolderDetail: (clientGuid: string, k1Partner: IK1ShareHolderDetails, isEmailChange: boolean, isPreview: boolean, callBack?: () => void) =>
            dispatch(K1DistributeStore.updateK1ShareHolderDetail(clientGuid, k1Partner, isEmailChange, isPreview, callBack)),

        sendMailToShareHolder: (clientId: string, partners: IK1ShareHolderDetails[], callback: () => void) => dispatch(K1DistributeStore.sendMailToShareHolder(clientId, partners, callback)),
        requestCurrentStepAndUserRole: (clientGuid: string, successCallback?: (clientProcessState: IClientProcessViewModel) => void) => dispatch(SignProcessStore.requestCurrentStepAndUserRole(clientGuid, successCallback)),
        downloadPreviewFilingInstruction: (clientGuid: string) => dispatch(PayStore.downloadPreviewFilingInstruction(clientGuid)),
        downloadPreviewAllVouchers: (clientId: string) => dispatch(PayStore.downloadPreviewAllVouchers(clientId)),

		requestTutorialInfoPreview: (clientId: string) => dispatch(TutorialStore.requestTutorialInfoPreview(clientId)),
		hideReviewTutorialPreview: (clientId: string, doNotShowNextTime: boolean) => dispatch(TutorialStore.hideReviewTutorialPreview(clientId, doNotShowNextTime)),
		hideSummaryTutorialPreview: (clientId: string, doNotShowNextTime: boolean) => dispatch(TutorialStore.hideSummaryTutorialPreview(clientId, doNotShowNextTime)),

        requestAdditionalEsignDocument: (clientId: string) => dispatch(DownloadableDocumentsStore.requestAdditionalEsignDocument(clientId)),
        requestMyDownload: (clientGuid: string) => dispatch(DownloadableDocumentsStore.requestMyDownload(clientGuid)),
		downloadAllAdditionEsignDocuments: (clientId: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadAllAdditionEsignDocuments(clientId, fileName)),
        downloadAllAdditionEsignDocumentsForPreview: (clientId: string, fileName: string) => dispatch(DownloadableDocumentsStore.downloadAllAdditionEsignDocumentsForPreview(clientId, fileName)),
        addVoucherPayemnt: (clientId: string, voucher: IVoucher, Callback?: () => void) => dispatch(PayStore.addVoucherPayemnt(clientId, voucher,Callback)),
        updateVoucherPayemnt: (clientId: string, voucher: IVoucher, Callback?: () => void) => dispatch(PayStore.updateVoucherPayemnt(clientId, voucher, Callback)),
        updateSignBehalfSpouseState: () => dispatch(EsignStore.updateSignBehalfSpouseState()),
        requestPaymentTransaction: (clientId: string) => dispatch(PaymentStore.requestPaymentTransaction(clientId)),
        requestStripePublicAPIKey:(clientId: string) => dispatch(PaymentStore.requestStripePublicAPIKey(clientId)),
        clearPaymentDetails: (clientId: string, voucher: IVoucher, Callback?: () => void) => dispatch(PayStore.clearPaymentDetails(clientId, voucher, Callback)),

        addPaperFileConsentToClientTracking: (clientId: string, callback?: () => void) => dispatch(PaperFileReturnStore.addPaperFileConsentToClientTracking(clientId, callback)),
        updatePaperFileConsent: (clientId: string, callback?: () => void) => dispatch(PaperFileReturnStore.updatePaperFileConsent(clientId, callback)),
        addPaperFilePrintToClientTracking: (clientId: string, paperFile: IPaperFileReturnDetails, callback?: () => void) => dispatch(PaperFileReturnStore.addPaperFilePrintToClientTracking(clientId, paperFile, callback)),
        addPaperFilePrintAllToClientTracking: (clientId: string, callback?: () => void) => dispatch(PaperFileReturnStore.addPaperFilePrintAllToClientTracking(clientId, callback)),
        downloadAllPaperFileReturns: (clientId: string, callback?: () => void) => dispatch(PaperFileReturnStore.downloadAllPaperFileReturns(clientId, callback)),
        downloadIndividualPaperFileReturn: (clientId: string, paperFile: IPaperFileReturnDetails, callback?: () => void) => dispatch(PaperFileReturnStore.downloadIndividualPaperFileReturn(clientId, paperFile, callback)),
        getPaperFileDocumentStream: (clientId: string, documentGuid: string, paperFile: IPaperFileReturnDetails, callback?: (data: any) => void) => dispatch(PaperFileReturnStore.getPaperFileDocumentStream(clientId, documentGuid, paperFile, callback)),
        getMergedPaperFileDocumentStream: (clientId: string, callback?: (url: any) => void) => dispatch(PaperFileReturnStore.getMergedPaperFileDocumentStream(clientId, callback)),
        requestPaperFileReturnDetails: (clientGuid: string, pageNo: number, pageSize: number, sortBy: string, sortOrder: string, searchText: string) => dispatch(PaperFileReturnStore.requestPaperFileReturnDetails(clientGuid, pageNo, pageSize, sortBy, sortOrder, searchText)),
        getDocumentUpdatedStatus: (clientId: string) => dispatch(DocumentStatusStore.getDocumentUpdatedStatus(clientId)),
        SetDocumentIsDownloaded: (clientId: string) => dispatch(DocumentStatusStore.SetDocumentIsDownloaded(clientId)),
        
        bulkSendMailToShareHolder: (clientId: string, unSelectedRows: IK1ShareHolderDetails[], callback: () => void) =>
            dispatch(K1DistributeStore.bulkSendMailToShareHolder(clientId, unSelectedRows, callback)),   

        downloadAllK1DocumentsAsync: (clientId: string, engagementTpe: EngagementTypeS, callback?:()=> void) => 
            dispatch(TrialK1DistributeStore.downloadAllK1DocumentsAsync(clientId, engagementTpe, callback)),
        
        downloadBulkSelectedK1DocumentForPreviewAsync: (clientId: string, shareHolders: IK1ShareHolderDetails[], engagementTpe: EngagementTypeS, callback?:()=>void) =>
            dispatch(K1DistributeStore.downloadBulkSelectedK1DocumentForPreviewAsync(clientId, shareHolders, engagementTpe, callback)),
    }
}


const connector = connect(mapStateToProps, mapDispatchToProps);

export default connector(PreviewComponent);