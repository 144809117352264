import { Action, Reducer } from "redux";
import { AppThunkAction } from "../";
import { actionTypes } from "../ActionTypes";
import { StatusType, NotificationAction } from "../Common/NotificationStore";
import {
  ICommonDataViewModel,
  initialCommonData,
} from "../../core/domain/viewModels/ICommonDataViewModel";
import { ITaxingAuthority } from "../../core/domain/models/ITaxingAuthority";
import { initializeAxios } from "../../core/services/dataAccess/DataService.Axios";
import { AxiosResponse } from "axios";
import { ErrorMessages } from "../../components/Common/Constants";
import { IAccessCodeValidation } from "../../core/domain/viewModels/ICommon";
import { TelemetryLogger } from "../../components/Logger/AppInsights";
import { IDocumentTransaction } from "../../core/domain/models/IDocumentTransaction";

const logger = TelemetryLogger.getInstance();

interface RequestTaxingAuthorityAction {
  type: actionTypes.TAXING_AUTHORITY_REQUEST;
  id: string;
}

interface ResponseTaxingAuthorityAction {
  type: actionTypes.TAXING_AUTHORITY_RESPONSE;
  data: ITaxingAuthority[];
}

interface FailureTaxingAuthorityAction {
  type: actionTypes.TAXING_AUTHORITY_FAILURE;
  id: string;
}

interface RequestIceServerAction {
  type: actionTypes.ICE_SERVER_REQUEST;
  id: string;
}

interface ResponseIceServerAction {
  type: actionTypes.ICE_SERVER_RESPONSE;
  data: RTCIceServer[];
}

interface RequestWebRTCSignalRHubBaseURLAction {
  type: actionTypes.WebRTC_SignalR_Hub_Base_URL_REQUEST;
  id: string;
}

interface ResponseWebRTCSignalRHubBaseURLAction {
  type: actionTypes.WebRTC_SignalR_Hub_Base_URL_RESPONSE;
  data: string;
}

interface FailureIceServerAction {
  type: actionTypes.ICE_SERVER_FAILURE;
  id: string;
}

type KnownAction = DispatchAction | NotificationAction;

type DispatchAction =
  | ResponseTaxingAuthorityAction
  | RequestTaxingAuthorityAction
  | FailureTaxingAuthorityAction
  | RequestIceServerAction
  | ResponseIceServerAction
  | RequestWebRTCSignalRHubBaseURLAction
  | ResponseWebRTCSignalRHubBaseURLAction
  | FailureIceServerAction;

export const actionCreators = {
  requestAllTaxingAuthorities:
    (id: string, callback?: () => void): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let state = getState();
      dispatch({ type: actionTypes.TAXING_AUTHORITY_REQUEST, id: id });
      return initializeAxios()
        .get<ITaxingAuthority[]>("api/Helper/GetAllTaxingAuthorityAsync/" + id)
        .then(function (response: AxiosResponse<ITaxingAuthority[]>) {
          callback && callback();
          dispatch({
            type: actionTypes.TAXING_AUTHORITY_RESPONSE,
            data: response.data,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : ErrorMessages.TaxingAuthorityError,
            statusType: StatusType.Error,
          });
          dispatch({ type: actionTypes.TAXING_AUTHORITY_FAILURE, id: id });
          logger.trackWarning(
            `requestAllTaxingAuthorities failed with error ${error.message} for client: ${id}`,
            { ClientId: id }
          );
        });
    },
  validateTimeBasedAccessCode:
    (
      accessCode: IAccessCodeValidation,
      callback: () => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      const options = {
        headers: {
          Accept: "application/json, text/plain, *",
          "Content-Type": "application/json; charset=utf-8",
        },
      };
      return initializeAxios()
        .post<boolean>(
          "api/Helper/ValidateTimeBasedAccessCode/" + accessCode.clientGuid,
          accessCode,
          options
        )
        .then(function (response: AxiosResponse<boolean>) {
          if (response.data) {
            callback();
          } else {
            dispatch({
              type: actionTypes.NOTIFICATION,
              statusMessage: ErrorMessages.CodeValidationFailed,
              statusType: StatusType.Warning,
            });
          }
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : ErrorMessages.CodeValidationError,
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            `validateTimeBasedAccessCode failed with error ${error.message} for client: ${accessCode.clientGuid}`,
            { ClientId: accessCode.clientGuid }
          );
        });
    },
  getIceServers:
    (id: string): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let state = getState();
      dispatch({ type: actionTypes.ICE_SERVER_REQUEST, id: id });
      return initializeAxios()
        .get<RTCIceServer[]>("api/Helper/GetIceServers/" + id)
        .then(function (response: AxiosResponse<RTCIceServer[]>) {
          dispatch({
            type: actionTypes.ICE_SERVER_RESPONSE,
            data: response.data,
          });
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response
              ? error.response.statusText
              : ErrorMessages.TaxingAuthorityError,
            statusType: StatusType.Error,
          });
          dispatch({ type: actionTypes.ICE_SERVER_FAILURE, id: id });
          logger.trackWarning(
            `getIceServers failed with error ${error.message} for client: ${id}`,
            { ClientId: id }
          );
        });
    },
  getWalkMeScript:
    (
      id: string,
      callback: (script: string) => void,
      isAuthorized?: boolean
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let baseUri = "api/Coverpage/GetWalkMeScriptAsync/";

      if (isAuthorized) {
        baseUri = "api/Helper/GetWalkMeScriptAsync/";
      }

      return initializeAxios()
        .get<string>(baseUri + id)
        .then(function (response: AxiosResponse<string>) {
          callback(response.data);
        })
        .catch(function (error: any) {
          logger.trackWarning(
            `getWalkMeScriptAsync failed with error ${error.message} for client: ${id}`,
            { ClientId: id }
          );
        });
    },
  getWebRTCSignalRHubBaseURL:
    (
      id: string,
      callback: (signalRHubBaseURL: string) => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let state = getState();
      dispatch({
        type: actionTypes.WebRTC_SignalR_Hub_Base_URL_REQUEST,
        id: id,
      });
      return initializeAxios()
        .get<string>("api/Helper/GetWebRTCSignalRHubBaseURLAsync/" + id)
        .then(function (response: AxiosResponse<string>) {
          //callback(response.data);
          dispatch({
            type: actionTypes.WebRTC_SignalR_Hub_Base_URL_RESPONSE,
            data: response.data,
          });
        })
        .catch(function (error: any) {
          logger.trackWarning(
            `GetWebRTCSignalRHubBaseURLAsync failed with error ${error.message} for client: ${id}`,
            { ClientId: id }
          );
        });
    },
  getDocumentTransactions:
    (
      clientId: string,
      callback: (documentTransactions: IDocumentTransaction[]) => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      let state = getState();
      return initializeAxios()
        .get<IDocumentTransaction>(
          `api/Esign/GetDocumentClientTracking/${clientId}`
        )
        .then(function (responseResult: any) {
          callback(responseResult.data);
        })
        .catch(function (error: any) {
          dispatch({
            type: actionTypes.NOTIFICATION,
            statusMessage: error.response?.statusText ?? error.message,
            statusType: StatusType.Error,
          });
          logger.trackWarning(
            ` fetching Get Document ClientTracking failed with message  ${error.message} for client Id: ${clientId}`,
            { ClientId: clientId }
          );
        });
    },
  getClientHubDomainURL:
    (
      id: string,
      callback: (url: string) => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      return initializeAxios()
        .get<string>("api/Helper/GetClientHubDomainURL/" + id)
        .then(function (response: AxiosResponse<string>) {
          callback(response.data);
        })
        .catch(function (error: any) {
          logger.trackWarning(
            `RedirectToGetClientHubDomainURL failed with error ${error.message} for client: ${id}`,
            { ClientId: id }
          );
        });
    },
  checkIfUserExistsInOneHub:
    (
      id: string,
      callback: (isUserExists: boolean) => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      return initializeAxios()
        .get<boolean>("api/Helper/CheckIfUserExistsInOneHub/" + id)
        .then(function (response: AxiosResponse<boolean>) {
          callback(response.data);
        })
        .catch(function (error: any) {
          logger.trackWarning(
            `CheckIfUserExistsInOneHub failed with error ${error.message} for client: ${id}`,
            { ClientId: id }
          );
        });
    },
  getOnehubRedirectURL:
    (
      id: string,
      callback: (url: string) => void
    ): AppThunkAction<KnownAction> =>
    (dispatch, getState) => {
      return initializeAxios()
        .get<string>("api/Helper/GetOnehubRedirectURL/" + id)
        .then(function (response: AxiosResponse<string>) {
          callback(response.data);
        })
        .catch(function (error: any) {
          logger.trackWarning(
            `RedirectToGetOnehubRedirectURL failed with error ${error.message} for client: ${id}`,
            { ClientId: id }
          );
        });
    },
};

export const reducer: Reducer<ICommonDataViewModel> = (
  state: ICommonDataViewModel = initialCommonData,
  incomingAction: Action
) => {
  const action = incomingAction as DispatchAction;
  const data = Object.assign({}, state);
  switch (action.type) {
    case actionTypes.TAXING_AUTHORITY_REQUEST:
      return data;
    case actionTypes.TAXING_AUTHORITY_RESPONSE:
      data.taxingAuthorities = action.data;
      return data;
    case actionTypes.ICE_SERVER_REQUEST:
      return data;
    case actionTypes.ICE_SERVER_RESPONSE:
      data.iceServers = action.data;
      return data;
    case actionTypes.WebRTC_SignalR_Hub_Base_URL_REQUEST:
      return data;
    case actionTypes.WebRTC_SignalR_Hub_Base_URL_RESPONSE:
      data.webRTCSignalRHubBaseURL = action.data;
      return data;
    default:
      return state || initialCommonData;
  }
};
