
export enum ClientType {
    Undefined = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    Shareholder,
    CcRecipient,
    Delegatee,
    Controller
}

export enum EngagementType {
    None,
    E1040 = 1,
    E1065,
    E1120,
    E1120S,
    E1041,
    E1040NR
}

export enum EngagementTypeS {
    None = "None",
    E1040 = "E1040",
    E1065 = "E1065",
    E1120 = "E1120",
    E1120S = "E1120S",
    E1041 = "E1041",
    E1040NR = "E1040NR"
}

export enum DocumentStatus {
    None = 0,
    UPLOADED,
    ERROR,
    READY,
    PROCESSING,
    REVIEW,
    USERSIGNED,
    DELIVERED,
    APPROVEDFORDELIVERY,
    PREPARINGFORDELIVERY,
    DELIVERYFAILED,
    NONSUPPORTED,
    RECALLED,
    DELETED
}

export enum SignatureStatus {
    None = 0,
    ESigned,
    ManuallySigned,
    Uploaded,
    AwaitingESign,
    AwaitingUpload,
    Locked,
    MailOrFax,
    SignedAndESigned,
    AutoParsed,
    Processing,
    Delivering,
    Delivered,
    DeliveryFailed,
    PartiallySigned,
    SignatureStampingFailed = 21,
    ESignedInOffice
}

export enum DefaultSenderInfoType {
    None = "None",
    Company = "Company",
    User = "User"
}

export enum SignatureType {
    None,
    ESign = 1,
    ESignWhenAllIncludedReturnsAllowed,
    ManualSign
}

export enum KBAMode {
    Test = 0,
    Live = 1
}

export enum TransmittalDownloadOption {
    None = "None",
    IncludeTransmittalInTaxReturnPDF = "IncludeTransmittalInTaxReturnPDF",
    CreateSeparateTransmittalPDF = "CreateSeparateTransmittalPDF"
}
export enum VoucherDownloadOption {
    None = "None",
    SeparateByQuarterlyDueDates = "SeparateByQuarterlyDueDates",
    GroupInSinglePDF = "GroupInSinglePDF"
}
export enum TaxpayerAuthenticationType {
    None = "None",
    Last4ofSSN = "Last4ofSSN",
    First4ofSSN = "First4ofSSN"
}
export enum SignatureFormSelectionType {
    None = "None",
    ESign = "ESign",
    ESignWhenAllIncludedReturnsAllowed = "ESignWhenAllIncludedReturnsAllowed",
    ManualSign = "ManualSign"
}

export enum NotificationType {
    Info,
    Error,
    Success,
    Warning
}

export enum KBATransactionResultType {
    passed,
    failed,
    error,
    questions,
    accepted,
    retryExceeded
}

export enum DistributeType {
    Electronic,
    Manual
}

export enum ConsentStatus {
    None,
    Declined,
    Agreed
}

export enum EntityType {
    None,
    Individual,
    Entity 
}

export enum K1ReportStatus {
    None = 0,
    Updated,
    Confirmed,
    Pending
}
export enum Role {
    None = 0,
    Taxpayer,
    Spouse,
    Partner,
    Partnership,
    Shareholder,
    CPA,
    Delegatee,
    Controller,
    InOfficeESign
}

export enum PaymentGateway {
    None = 0,
    Stripe = 1
}

export enum Origin {
    None = 0,
    CPA = 1,
    TaxPayer = 2,
    RecognizerService = 3,
    DeliveryService = 4,
    PaymentService = 5,
    Shareholder = 6
}

export enum PaymentFlowType {
    None = 0,
    PayNow = 1,
    PayasYouGo = 2
}

export enum PaymentState {
    None = 0,
    Success = 1,
    Incomplete = 2,
    Failed = 3,
    Other = 4,
    RequiresPaymentMethod = 5,
    RequiresConfirmation = 6,
    RequiresAction = 7,
    Initialized = 8,
    Processing = 9,
    RequiresCapture = 10,
    Cancelled = 11,
    Invalid = 12
}

export enum RedirectionSourcePage {
    None = 0,
    TaxpayerFlow = 1,
    Signcompleted = 2,
    Pay = 3,
    Download = 4,
    DistributeK1 = 5
}