import * as React from 'react';
import { BootstrapTable, TableHeaderColumn, SortOrder } from 'react-bootstrap-table';
import { IK1ShareHolderDetails } from '../../core/domain/models/IK1Distribute';
import { match } from 'react-router';
import { EngagementTypeS } from '../../core/common/Enums';
import { ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { GetK1EngagementType } from '../../core/domain/models/IK1Distribute';
import 'react-bootstrap-table/dist/react-bootstrap-table.min.css'; 
import { ILoader } from '../../core/utilities/ui/Loader';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { OverlayLoader } from '../Common/Loader/OverlayLoader';
import { SvgDownloadIcon, SortInitial, SortDescending, SortAscending } from '../Common/Icons/SvgIcons';
import { Alert } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { handleKeyDown } from '../Helper/HelperFunction';

const loader = container.get<ILoader>(TYPES.ILoader);

interface DistributeManualTableProps {
    k1Data: IK1ShareHolderDetails[];
    onSortChange: any;
    onPageChange: any;
    pageNo: number;
    pageSize: number;
    onSearchChange: (searchString: string) => void;
    onRowSelect: (row: any, isSelected: any, e: any) => void;
    onSelectAll: (isSelected: any, rows: any, e: any) => void;
    selectedK1Partners: IK1ShareHolderDetails[]
    taxReturn: ITaxReturn;
    match: match;
    downloadIndividualK1DocumentAsync: (
        clientId: string,
        shareHolder: IK1ShareHolderDetails,
        engagementTpe: EngagementTypeS
    ) => void;
    isK1Loading: boolean;
    onBulkSelectionChange: (isBulkSelection: boolean) => void;
    showBulkSelectionMessage: boolean;
}


export class DistributeManualTable extends React.Component<DistributeManualTableProps, {}>{
    constructor(props: any) {
        super(props);
    }

    private tableRef = React.createRef<BootstrapTable>();

    componentDidMount(): void {
        const thElements = Array.from(document.querySelectorAll('th'));
        const thElementsWithInput = thElements.filter((th: any) => {
            return th.querySelector('input.react-bs-select-all') !== null;
        });
        if (thElementsWithInput?.length > 0) {
            thElementsWithInput.forEach((th: any) => {
                th.setAttribute('aria-label', 'Select All');
            });
        }
    }

    componentDidUpdate(prevProps: Readonly<DistributeManualTableProps>, prevState: Readonly<{}>, snapshot?: any): void {
        if (prevProps.k1Data?.length !== this.props.k1Data?.length) {
            const checkboxElements = document.querySelectorAll('.distributeManualTbl input[type="checkbox"]');
            if (checkboxElements?.length > 0) {
                checkboxElements.forEach((element: any) => {
                    element.setAttribute('aria-label', 'Select Row');
                });
            }
        }
    }

    handleK1Download = (shareHolderId: number) => {
        let shareHolder = this.props.k1Data.filter(x => x.shareHolderId === shareHolderId);
        if (shareHolder) {
            let param: any = this.props.match.params;
            this.props.downloadIndividualK1DocumentAsync(param.clientId, shareHolder[0], this.props.taxReturn.engagementType);
        }
    }

    private showBulkAlertMessage() {
        let totalCount = this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0
		if (totalCount > this.props.pageSize && this.props.showBulkSelectionMessage)
			return (<div className="col-sm-6 col-lg-8" style={{ display: "inline-block", zIndex: 10, padding: '0px 0px' }} >
				<Alert variant="warning" style={{ padding: "6px", width: "fit-content", marginBottom: '0px' }}>
					<span
						className="fa fa-exclamation-triangle"
						style={{ marginRight: '5px', paddingLeft: "5px" }}>
					</span> All {this.props.pageSize} records on this page are selected. To select the remaining {totalCount - this.props.pageSize} filtered records,
					<Link to={"#"} className='ml-1' style={{textDecoration: 'underline'}} onClick={() => this.props.onBulkSelectionChange(true)}>Click Here</Link>
				</Alert>
			</div>);
	}

    private k1StatusAction = (cell: any, row: any) => {
        return (
            <div>
                <span
                    className="k1-partner-button"
                    data-test-auto="74EFE1B0-B246-41B0-87B8-146EDE68A834"
                    title={"Download"}
                    onClick={() => { this.handleK1Download(row.shareHolderId) }}
                    tabIndex={0}
                    onKeyDown={(e: any) => handleKeyDown(e, () => this.handleK1Download(row.shareHolderId))}
                >
                    <SvgDownloadIcon width={16} height={16} />
                    Download
                </span> 
            </div>
        );
    }

    private defaultType = (cell: any, row: any) => {
        return <span title={cell} className="ellipsis" data-test-auto="40AFA486-A699-4E58-9963-BE6A7778A603">{cell}</span>;
    }

    private customHeaderName = () => {
        return this.props.taxReturn && GetK1EngagementType(this.props.taxReturn.engagementType);
    }

    private renderShowsTotal = (start: number, to: number, total: number) => {
        return (
            <p>
                Showing {start} to {to} of {total} entries
            </p>
        );
    }

    sortRenderer = (direction: SortOrder | null, fieldName: string) => {
        if (fieldName === 'button') {
            return <></>
        }
        if (fieldName === 'name') {
            if (!direction) {
                return <SortInitial />
            }
            if (direction === 'asc') {
                return <SortAscending />
            }
            else {
                return <SortDescending />
            }
        }
    }

    private columns = [
        {
            header: '',
            key: 'rowIndex',
            isKey: true,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: true,
            width: 'auto',

        },
        {
            header: this.customHeaderName(),
            key: 'name',
            isKey: false,
            dataFormat: this.defaultType,
            columnClassName: '',
            dataSort: true,
            toolTip: false,
            isHidden: false,
            width: 'auto',
        },
        {
            header: 'Actions',
            key: 'button',
            isKey: false,
            dataFormat: this.k1StatusAction,
            columnClassName: '',
            dataSort: false,
            toolTip: false,
            isHidden: false,
            width: '150px',
        },
    ];

    private setNoContent() {
        if (this.props.isK1Loading) {
            if (document.getElementById("overlay-loader")) {
                loader.show();
                return;
            }
            return (<OverlayLoader />);
        } else {
            loader.hide();
            return "No records found";
        }
    }

    public render() {

        const options = {
            onSortChange: this.props.onSortChange,
            onSearchChange: this.props.onSearchChange,
            onPageChange: this.props.onPageChange,
            sizePerPage: this.props.pageSize,
            page: this.props.pageNo,
            paginationShowsTotal: this.renderShowsTotal,
            clearSearch: false,
            hideSizePerPage: true,
            noDataText: this.setNoContent(),
        };

        const data = this.props.k1Data && this.props.k1Data.map((model, index) => {
            return {
                name: model.name,
                rowIndex: index,
                button: 'Actions',
                shareHolderId: model.shareHolderId
            }
        });

        let selected: number[] = [];
        if (this.props.selectedK1Partners.length > 0) {
            this.props.selectedK1Partners.forEach(k1Partner => {
                let rowIndex = data.findIndex(row => row.shareHolderId == k1Partner.shareHolderId );
                if(rowIndex != -1)
                    selected.push(rowIndex);
            });
        }

        let selectRowProp: any =
        {
            mode: 'checkbox',
            onSelect: this.props.onRowSelect,
            onSelectAll: this.props.onSelectAll,
            selected: selected,
            className: 'row-selected',
            columnWidth: '40px'
        };
        let totalCount = this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0 
        return (
            <>
            {this.showBulkAlertMessage()}
             <div className="distributeManualTbl" data-test-auto="00D28309-65F6-44DF-AF2D-4A2B7EF65C5C">
                <BootstrapTable
                    ref={this.tableRef}
                    data={data}
                    remote={true}
                    pagination={totalCount > this.props.pageSize}
                    hover={true} 
                    options={options}
                    selectRow={selectRowProp}
                    withoutTabIndex={true}
                    bordered={false}
                    fetchInfo={{ dataTotalSize: this.props && this.props.k1Data.length > 0 ? this.props.k1Data[0].count : 0 }}
                >
                    {this.columns.map((value, index) => {
                        const header = value.header === "" && value.isHidden ? `Column ${index + 1}` : value.header;
                        const ref = React.createRef<TableHeaderColumn>();
                        return <TableHeaderColumn
                            key={index}
                            ref={ref}
                            isKey={value.isKey}
                            dataField={value.key}
                            hidden={value.isHidden}
                            width={value.width}
                            dataFormat={value.dataFormat}
                            columnClassName={value.columnClassName}
                            columnTitle={value.toolTip}
                            dataSort={value.dataSort}
                            caretRender={this.sortRenderer}
                        >
                            {header}
                        </TableHeaderColumn>;
                    })}
                </BootstrapTable>
            </div>
            </>
           

        )
    }
}