import {
  SvgIconSummaryWizardActive,
  SvgIconReviewWizardActive,
  SvgIconSignWizardActive,
  SvgIconDistributeK1sWizardActive,
  SvgIconMakePaymentsWizardActive,
  SvgIconSendElectronically,
  SvgIconSendManually,
  SvgIconDownloadAll,
  SvgIconContactHeader,
  SvgIconCalendarHeader,
  SvgIconAccountHeader,
  SvgIconManualSignature,
  SvgIconEsign,
  SvgIconSignDocumentsCompletedSummary,
  SvgIconDownloadDocumentsSummary,
  SvgIconTaxPaymentsCompletedSummary,
  SvgIconPrintAndSend,
  SvgIconPayTaxPrep,
  SvgIconDistributeK1sSummary,
  SvgIconDownloadAllSm,
  SvgIconViewDocumentsSummary,
  SvgIconInvoicePaymentsCompletedSummary,
  SvgIconPaperFileCompletedSummary,
  SvgIconSendForSignature,
  SvgIconDelegateSignature,
  SvgIconDelegateeCompletedSummary,
  InfoCircle,
  SvgElectronicK1DistributionIcon,
  SvgMailHardCopyIcon,
} from "./Icons/SvgIcons";
export const SiginingConstants = {
  StatusMessage: {
    ManualSignSelectConfirmationMessage:
      "Please confirm whether you want to continue with manual sign?",
    EditSpouseEmail: "Change Spouse Email",
    ManualSignReviewDocumentsRedirectConfirmationMessage:
      "You have submitted your signed documents to your firm outside of " +
      "SafeSend Returns. If you would like to view/download the unsigned copy of your signature forms please click on 'Continue'.",
  },
  WarningMessage: {
    InvalidEmailAddress: "Please enter valid email address",
    InvalidDOB: "Please select valid date of birth",
    InvalidSSN: "Please enter a valid 4 digit ssn",
    ManualSignStatusWarning:
      "You have opted to submit your signed documents to your firm outside of SafeSend Returns. Please refresh to navigate to summary page.",
  },
  SelectSignMessage:
    "Select the method you would like to use to sign your documents",
  SelectSignTitle: "Sign Tax Documents",
  ESignImageCaption: "e-Sign",
  EsignCaption: "e-Sign",
  ManuallySignImageCaption: "Manually Sign",
  ManuallySignCaption: "Manual Signature",
  EditSecondSignerMobileNumber: "Edit Spouse Number",
  AddSecondSignerMobileNumber: "Add Spouse Number",
  DelegateToSomeOne: "Delegate Signing",
  DelegationDescription: `Send tax forms to partners or \n shareholders for signatures`,
};

export const ErrorMessages = {
  PreparerDocumentSettingError: "Error occured while loading DocumentSettings",
  HeaderInfoError: "Error occurred while loading Header Information",
  TaxingAuthorityError: "Error occured while loading Taxing Authority",
  SignedDocumentError: "Error occurred while loading signed documents model",
  AddSignedDocument: "Error occcured while uploading Signed document",
  DeleteSignedDocument: "Error occured while deleting Signed document",
  CompleteManualSign: "Error occured while completing the signed document",
  SummaryDetails: "Error occured while loading the Summary of the document",
  EsignModel: "Error occured while loading Esign Page",
  Signers: "Error occured while loading Signer information",
  ClientAuthentication: "Error occurred while authenticating user",
  ClientValidation: "Error occurred while validating client answer",
  UpadateSpouseMail: "Error occurred while updating spouse email",
  UpdatingMobileNumber: "Error occurred while updating mobile number",
  UpdatingDob: "Error occured while updating Date of Birth",
  SingatureControl: "Error occurred while requesting signature controls",
  DocumentSignatureSetting:
    "Error occurred while updating document signature settings",
  CompanyDetails: "Error occured while loading Company Details",
  DocumentReviewModel: "Error occured while loading Review Documents",
  RequestDocumentError: "Error occurred while requesting Tax Document model",
  RequestClientType: "Error occurred while fetching the Client Type",
  RequestKBAStatus:
    "Error occurred while loading Knowledge Based Authentication",
  RequestNextSigner: "Error occured while loading Next Signer Details",
  UpdateProcessInfoError: "Error occurred while updating process information",
  GetLastVisitedStepError: "Error occurred while requesting GetLastVisitedStep",
  GetCurrentStepAndRoleError:
    "Error occurred while requesting GetCurrentStepAndRole",
  ChangeMobileNumberError: "Unable to update the Mobile Number",
  CodeValidationError: "Error occured while validating screen share code",
  CodeValidationFailed: "The code you entered is not valid",
  GetTutorialInfoError: "Error occurred while requesting Tutorial Info",
  HideSummaryTutorialError: "Error occurred while hiding Summary Tutorial",
  HideReviewTutorialError: "Error occurred while hiding Review Tutorial",
  RequestTransactionIdError: "Error occured while fetching transaction Id",
  RequestPaymentTransactionError: "Error occured while paying the money",
  SavePaymentTransactionError: "Error occured while saving payment transaction",
  RequestStripePaymentURLError: "Error occured while downloading receipt",
  GetSessionTimeOutSecondsError:
    "Error occured while fetching Idle session timeout seconds",
  RequestStripePublicAPIKey:
    "Error occured while fetching Idle Stripe public key",
  UTEOnehubNotAccesibleForClientView:
    "Client Portal is not accessible within Client View",
};

export const MyAccountConstants = {
  StatusMessage: {
    EditSpouseDetails: "Spouse details updated successfully",
    EditTaxpayerDetails: "Taxpayer details updated successfully",
    EditMobileNumber: "Mobile number updated successfully",
    UpdateInformation: "Account details updated successfully",
  },
  WarningMessage: {
    InvalidEmailAddress: "Please enter valid email address",
  },
};

export const MyDownloadsConstants = {
  StatusMessage:
    'Download process may take some time, the downloaded <FILENAME> will be available in "Downloads"',
  DeleteMyDownload: "Selected File has been deleted",
  ClearAllMyDownloads: "All Downloaded files have been deleted",
  DeleteSingleFileWarning:
    "File cannot be deleted, since Download is in progress.",
  DialogBox: {
    ConfirmDeletionSingle: "Are you sure you want to delete this file?",
    ConfirmDeletionAll: "Are you sure you want to clear all files?",
    Confirm: "Yes",
    Cancel: "Cancel",
    Title: "Confirm Deletion",
  },
  DownloadFiles: "Choose the document(s) to download:",
  DownloadDocument: "Download",
  DocumentsNotSelectedWarning:
    "Please make sure you have selected atleast one document to download",
};

export const ValidationContants = {
  PhoneNumberWarning: "Please enter mobile number.",
  PhoneNumberLengthWarning: "Please enter a 10 digit mobile number.",
  CountryCodeWarning: "Please select country code",
  InvalidMobileNumberWarning:
    "Please enter a valid mobile number, according to the country code selected",
};

export const Common = {
  Wizard: {
    PreviousButtonLabel: "Back",
    NextButtonLabel: "Next",
    SendLater: "Send Later ",
    ForwardDocuments: "Forward",
    PayNow: "$ Pay Now",
  },
  DialogBox: {
    ConfirmationTitle: "Confirmation",
    DeleteTitle: "Delete",
    WarningTitle: "Warning",
    Cancel: "Cancel",
    Confrim: "Confirm",
    Delete: "Yes, Delete It",
    Yes: "Yes",
    No: "No",
  },
  Images: {
    download: SvgIconDownloadAll,
    SummaryStepIcon: SvgIconSummaryWizardActive,
    ReviewStepIcon: SvgIconReviewWizardActive,
    SignStepIcon: SvgIconSignWizardActive,
    PayStepIcon: SvgIconMakePaymentsWizardActive,
    DistributeStepIcon: SvgIconDistributeK1sWizardActive,
    SendElectronically: SvgIconSendElectronically,
    SendManually: SvgIconSendManually,
    ContactIcon: SvgIconContactHeader,
    CalendarIcon: SvgIconCalendarHeader,
    HeaderAccountIcon: SvgIconAccountHeader,
    ElectronicalSignIcon: SvgIconEsign,
    ManuallySignIcon: SvgIconManualSignature,
    DelegateIcon: SvgIconDelegateSignature,
    DownloadSmIcon: SvgIconDownloadAllSm,
    SendForSignatureIcon: SvgIconSendForSignature,
    ElectronicK1DistributionIcon: SvgElectronicK1DistributionIcon,
    MailHardCopyIcon: SvgMailHardCopyIcon,
  },
};

export const ManualSignConstants = {
  Title: "Manual Signatures Required",
  DownloadDocumentTitle: "Please download documents to continue",
  DownloadDocumentButtonText: "Download documents requiring signature",
  UploadDocumentTitle:
    "After manually signing, choose a method to send them to your tax preparer",
  UploadDocumentSecondaryTitle: "Upload Signed Documents",
  SendByMailText: "Send by Mail",
  SendByFaxText: "Send by Fax",
  FileDeleteConfirmation: "Are you sure you want to delete the file ?",
  DownloadEfileLink: "api/Download/GetManualSignDocument/",
  UploadAreaButtonText: "Choose File",
  UploadAreaText: "Drag documents here",
  UploadAreaMinimizedText: "Drag documents here or click here to upload",
  SupportedFiles:
    "Supports DOC, JPEG, PNG, PDF or Excel; file size no more than 500MB.",
  UploadNotAllowedMessage: "Upload not allowed in preview mode",
  SupportedFilesMessage:
    "Unsupported file type attached. We support PDF, DOC, Excel, PNG and JPEG file types.",
  ConfirmMessage:
    "All document(s) have been successfully uploaded and will be forwarded to <COMPANYNAME>. Do you want to proceed?",
  AuditFileName: "Signature_Audit_Report.pdf",
  DownloadZipSignedFileName: "_Other Signed Document.zip",
  DownloadZipUnSignedFileName: "_Other UnSigned Document.zip",
  DownloadZipMultipleUnSignedFileName: "_Other UnSigned Documents.zip",
  RedirectConfirmationMessage:
    "Your unsaved edits will be discarded, Are you sure ?",
  ReviewDocuments: "Review Sign Documents",
};

export const K1EngagementType = {
  E1065: "Partners",
  E1120S: "Shareholders",
  E1041: "Beneficiaries",
};

export const K1ShareHolderConstants = {
  DownloadAllK1Link: "api/Download/GetAllK1DocumentAsync/",
  WarningMessage: {
    EmailAdrressWarning: "Please enter email address.",
    InvalidEmailAddress: "Please enter valid email Address.",
    CheckBoxSelect: "Please select atleast one",
    EmailAdrressEmpty: "Email address can not be blank",
    EntityTypeWarning: "Please select partner type.",
    K1DistributionWarning:
      "Are you sure you would like to continue without distributing your K-1’s?",
    Cancel: "No, Return",
    Confirm: "Yes",
  },
  SuccessMessage: {
    emailUpdated: "Email address updated successfully",
    detailsUpdated: "K1 details updated successfully",
    emailSent: "Email has been successfully sent",
  },
  ElectronicCaption: "Electronically",
  MailHardCopyCaption: "Mail Hard Copies",
  ElectronicSubtitleCaption: "Email them from this website",
  MailHardCopySubtitleCaption: "Download Print, and Mail",
};

export const SignProcessConstants = {
  ProcessRecoveryConfirmation: "Sign Process Recovery Confirmation",
  ProcessRecoveryMessage:
    "Would like to resume where you left off or start over ?",
  ProcessRecoveryConfirmationDialogLeftButton: "Resume",
  ProcessRecoveryConfirmationDialogRightButton: "Start over",
  ProcessRecoveryFailedMessage:
    "some settings has been changed, starting from first",
};

export const CompletedSummaryConstant = {
  SpouseReminderAlertMessage:
    "Spouse electronic signature required to file taxes.",
  TaxpayerReminderAlertMessage:
    "Taxpayer electronic signature required to file taxes",
  InstructionText:
    "You can access and manage all of your tax documents by selecting an option below",
  SignatureStampingFailure:
    "Signed documents cannot be viewed or downloaded at this time. Please contact your tax preparer for additional help.",
  SecondSignerAlertMessage:
    "You have successfully <span style='font-weight: 700'>completed</span> all the necessary steps. <div>We are now waiting for <span style='color: #0973BA; font-weight: 600'><SIGNERNAME></span>'s signature.</div>",
  CompletedMessage:
    "All required steps for your <TAXYEAR> tax returns <br /> have been <span style='font-weight: 700'>completed!</span>",
  WelcomeMessage:
    "Hello, <span style='color: #05386B; font-weight: 600'><TAXPAYERNAME></span>",
  WelcomeMessage2: "Welcome <TAXPAYERNAME>",
  SendSigningReminderSuccess: "Signing Reminder has been successfully sent",
  SendSigningReminderFailure: "Failed to send the Signing Reminder",
  ManualSignMessageForMFJ:
    "You have opted for Manual Signature, please have <span style='color: #0973BA; font-weight: 600'><SIGNERNAME></span> <br />sign the documents as well and return the signed documents to your tax preparer.",
  ManualSignMessage:
    "You have opted for Manual Signature, please return the signed documents to your tax preparer.",
  sendReminderMessage:
    "The send reminder option is currently disabled since the Signature request is in progress.",
  Images: {
    InfoCircle: InfoCircle,
    ViewDocument: SvgIconViewDocumentsSummary,
    DownloadDocument: SvgIconDownloadDocumentsSummary,
    ViewDownloadSignedDocument: SvgIconSignDocumentsCompletedSummary,
    ViewForwardTaxDocument: SvgIconViewDocumentsSummary,
    MakeTaxPayment: SvgIconTaxPaymentsCompletedSummary,
    PrintFiledReturn: SvgIconPrintAndSend,
    PayTaxPrepFee: SvgIconPayTaxPrep,
    DistributeK1: SvgIconDistributeK1sSummary,
    InvoicePayment: SvgIconInvoicePaymentsCompletedSummary,
    PaperFile: SvgIconPaperFileCompletedSummary,
    Delegatee: SvgIconDelegateeCompletedSummary,
  },
  ImageCaption: {
    ViewReturn: "View Tax Return",
    DownloadDocumnt: "Download Documents",
    ViewDownloadSignedDocument: "View or Download Signed Documents",
    ViewForwardTaxDocuments: "View or Forward Tax Documents",
    MakeTaxPayment: "Make Tax Payments",
    PrintFiledReturn: "Print & Mail Paper File Returns",
    PayTaxPrepFee: "Pay or View Tax Preparation Fees",
    DistributeK1: "Distribute K-1s",
    UpdateDelegatee: "Update Delegatee",
    InvoicePayment: "Invoice Payment",
    ViewDownloadPaperFiledReturn: "View and Download Paper File Returns",
    PrintandMailPaperFiledReturn: "Print and Mail Paper File Returns",
  },
};

export const PayConstants = {
  VoucherSettingUpdated:
    "Payment Voucher Notification Settings Updated Successfully.",
  ErrorVoucherSettingUpdated:
    "Error occurred while updating payment Voucher document settings",
  SuccesVoucherPayementMessage: "Voucher Payment Updated Successfully",
  ErrorVoucherPaymentMessage: "Failed to Update Voucher Payment",
  paymentFailure: "Error occured while making payment, Please try again.",
  paymentSuccess: "Successfully paid",
  ClearVoucherDetailsSuccess: "Voucher details cleared successfully",
  ClearVoucherDetailsFailure: "Failed to clear voucher details",
  DownloadFailed: "Failed to download",
  ErrorVoucherPayment: "Error occured while making request, Please try again.",
  PaymentInstructionModalHeader: "Payment Instructions",
};

export const WelcomeSummaryConstants = {
  InstructionText: "Please follow these easy steps so your taxes can be filed.",
  WelcomeMessage: "Your <TAXYEAR> Tax Return is ready!",
};

export const PathConstants = {
  UTEPay: "/tax-payments",
  DistributeK1: "/distributeK1/",
  DistributeK1Electronically: "/distributeK1/electronic/",
  DistributeK1Manually: "/distributeK1/manual/",
  CompletionWizard: "/signcompleted/",
  Pay: "/pay/",
  ManualSign: "/manualsign/",
  SignFlow: "/signflow/",
  PreviewFlow: "/previewflow/",
  Download: "/download/",
  Review: "/review/",
  controllerpreviewflow: "/controllerpreviewflow/",
  ManualSignReviewDocuments: "/ReviewManualSignDocuments/",
  DelegateeSignComplete: "/Delegatee/SignCompleted/",
  DelegateeManualSignReviewDocuments: "/DelegateeReviewManualSignDocuments/",
  ClientLogin: "/CoverPage/Index/",
  ControllerLogin: "/GroupedReturns/Coverpage/Index/",
  CCRecipientLogin: "/CCRecipient/",
  DelegateeLogin: "/Delegatee/",
  PaperFileReturn: "/PaperFileReturn/",
};

export const getRoutePathUrl = (
  pathConstant: string,
  clientId: string,
  controllerId?: string
) => {
  return `${pathConstant}${controllerId ? controllerId + "/" : ""}${clientId}`;
};

export const DelegateePathConstants = {
  SignFlow: "/Delegatee/SignFlow/",
  ManualSign: "/Delegatee/ManualSign/",
  CompletionWizard: "/Delegatee/SignCompleted/",
  Logout: "/Authentication/DelegateeLogout/",
};

export const Permissions = {
  Read: "Read",
};
export const KBAConstants = {
  KBARetryExceedMessage:
    "As per IRS regulations after three failures at  Identity Verification you must manually sign Form(s) 8879",
  KBAFailureMessage: "KBA validation failed. Please enter correct answers.",
  KBAIdentificationFailure:
    "You do not qualify for the IRS required Knowledge Based Authentication.  As a result, you are unable to electronically sign your documents and you must manually sign Form(s) 8879.",
};

export const PaperFileReturnConstants = {
  ConsentAcknowledgementInstruction:
    "I acknowledge that it is my responsibility to PRINT, SIGN and MAIL all Paper File Tax Returns to the respective Tax Authority before the due date.",
  ConsentHeader: "Consent to Paper File Returns",
  ConsentButton: "I Consent",
  SkipButtonText: "Skip",
  Header: "Paper File Returns",
  SkipToNextButtonText: "Skip",
  CloseButtonText: "Close",
  TableFileNameHeader: "Return File",
  TableMailingAddress: "Mailing Address",
  PrintButtonInfo:
    "Printing all Paper file returns will open each return in a separate printing window.",
  DownloadAll: "Download All Paper File Returns",
  PreviewModeMessage: "This feature is not available in preview mode",
  PrintAll: "Print All Paper File Returns",
  AckInstructionforController:
    "The following tax returns must be Downloaded, Printed, Manually signed by the Taxpayer and Spouse, and mailed to the address provided.",
  AckInstructionforMutual:
    "The following tax returns must be Downloaded, Printed, Manually signed by both you and your spouse and mailed to the address provided.",
  AckInstructionAfterConsent:
    "<ClientName> has consented to Download, Print, manually sign and send the tax return to the Tax Authority.",
  AckInstruction:
    "The following tax returns must be Downloaded, Printed, Manually signed and mailed to the address provided.",
  SuccessMessage: {
    AddedToClientTracking: "Successfully added to client tracking",
    FileDownloaded: "File successfully downloaded",
  },
  ErrorMessage: {
    FailedToAddClientTracking: "Failed to add client tracking",
    FailedToFetchPaperFileReturns: "Failed to fetch paper file returns",
    FailedToDownloadPaperFileReturns: "Failed to download paper file returns",
    FailedToGetPaperFileReturnUrl: "Failed to get paper file return url",
    FailedDownloadAllDocument:
      "Failed to download document. Please try after sometime.",
    FailedToPrintAllDocument:
      "Failed to print document. Please try after sometime.",
  },
};

export const SummaryTurorialConstants = {
  TaxYearModal: {
    header: "Multiple Tax Year Available",
    body: "You may switch between different tax years by using the drop-down list located here.",
    doNotShowInstruction: "Do not show me this instruction again",
  },
  ContactPersonModal: {
    header: "Contact Person",
    body: "Please contact this person if you are experiencing issues navigating this website or completing the process.",
    doNotShowInstruction: "Do not show me this instruction again",
  },
  Buttons: {
    next: "Next",
    done: "Done",
  },
};

export const ReviewTurorialConstants = {
  ReviewModal: {
    header: "Select Documents To Review",
    body: "Please select all the Document Groups and review before proceeding to signing.",
    doNotShowInstruction: "Do not show me this instruction again",
  },
  Buttons: {
    next: "Next",
    done: "Done",
  },
};

export const AttachmentConstants = {
  ModalHeader: "Attachment Instructions",
  Note: '<b>Helpful Hints</b>:<ul><li>If you have the option of saving the file or opening it in an application, choose save the file.</li><li>Download each file individually or get everything at once in a single ZIP file by selecting "Download All".</li></ul>',
};

export const CCRecipientConstants = {
  ModalDescription:
    "Please provide the email addresses for sharing tax documents. " +
    'These documents will originate from <span class="ssr-default-email">noreply@safesendreturns.com</span>. ' +
    "Recipients must input an Access Code, which will be dispatched via a follow-up email or through text message confirmation.",
  Note: "<b>Note</b>: Recipients will only be able to download copies of tax documents. They will not be able to sign forms or manage payments.",
  DocumentForwardText: "Choose the document(s) to forward:",
  CcEmptyWarning: "Please make sure you have entered recipient email address",
  DocumentsNotSelectedWarning:
    "Please make sure you have selected atleast one document to forward",
  SuccessfulyMailedDocuments: "Email Sent Successfully",
  InvalidEmail: "Please make sure you have entered valid email address",
  ForwardDocumentPopupHeader: "Forward A Copy",
};

export const CCRecipientDownloadEndPoints = {
  TaxReturns: "api/CCRecipient/DownloadTaxReturnDocumentSasAsync/",
  Transmittals: "api/CCRecipient/DownloadTransmittalDocumentSasAsync/",
  Efile: "api/CCRecipient/DownloadEfileDocumentSasAsync/",
  Invoice: "api/CCRecipient/DownloadInvoiceDocumentSasAsync/",
  Voucher: "api/CCRecipient/DownloadVoucherDocumentSasAsync/",
  AdditionalEsignDocument:
    "api/CCRecipient/DownloadAdditionEsignDocumentSasAsync/",
  Attachments: "api/CCRecipient/DownloadAttachmentSasAsync/",
  PaperFiles: "api/CCRecipient/DownloadPaperFileSasAsync/",
};

export const VoucherPaymentConstants = {
  AddingVoucherPaymentNotAllowed:
    "Adding payment details is not allowed in preview mode",
};

export const DelegateeSignerConstants = {
  WarningMessage: {
    EmptyEmailAdrress: "Email address cannot be empty.",
    InvalidEmailAddress: "Please enter valid email Address.",
    EmptyFirstName: "Please enter delegatee first name.",
    EmptyLastName: "Please enter delegatee last name.",
    EmptyDescription: "Please enter the reason for delegation",
    EmptyCancelDelegationRemarks:
      "Please enter the reason for canceling the delegation",
    EmptyDeclineSignRemarks: "Please enter the reason for declining",
    InvalidConfirmEmail: "Please enter valid confirm email Address.",
    EmailDontMatch:
      "Actual email address and confirm email address don't match.",
    InvalidMobileNumber:
      "Please enter a valid mobile number, according to the country code selected",
    WarningUpdatingDelegatee:
      "You are not allowed to update delegatee as signing is completed by delegatee.",
    WarningCancelDelegation:
      "You are not allowed to cancel the delegation as signing is completed by delegatee.",
  },
  SuccessMessage: {
    SavedDelegateeDetailsSuccess: "Delegatee details saved successfully",
    UpdateDelegateeSuccess: "Updated delegatee details successfully",
    SavedCancelDelegationSuccess: "Delegation canceled successfully",
    UpdateMobileNumberSuccess: "Delegatee mobile number updated successfully",
    DeclineSignSuccess: "Document signing declined successfully",
  },
  ErrorMessage: {
    ErrorSavingDelegateeDetails: "Error occured while saving delegatee details",
    ErrorUpdatingDelegatee: "Error occured while updating delegatee details",
    ErrorCancelingDelegation: "Error occured while canceling delegation",
    ErrorUpdatingMobileNumber: "Error occured while updating mobile number",
    ErrorFetchingDelegatee: "Error occured while fetching delegatee details",
  },
  Info: {
    DelegateeSignerEmail: "Delegated the signing to <DelegateeEmail>",
    DelegatedStatus: "Delegated",
    DelegatedPreview: "DelegatedPreview",
  },
};
export const DownloadDocumentsConstants = {
  DownloadNotAllowed:
    "Documents are not available for download within Client View",
  EfileNotPresent: "Efile Documents are not available for download",
};

export const InvoicePaymentConstants = {
  SuccessMessage: {
    AddInvoicePaymentSuccess: "Invoice Payment Added Successfully",
    UpdateInvoicePaymentSuccess: "Invoice Payment Updated Successfully",
    ClearAllInvoicePaymentSuccess: "Invoice Payment Cleared Successfully",
  },
  ErrorMessage: {
    AddInvoicePaymentError: "Failed to Add Invoice Payment",
    UpdateInvoicePaymentError: "Failed to Update Invoice Payment",
    RequestInvoicePaymentError:
      "Error occured while fetching Invoice Payment Detail",
    ClearAllInvoicePaymentError: "Failed to Clear Invoice Payment",
    InvalidAmountPaid: "Amount can not be greater than the Invoice amount",
  },
  PaymentDueCaption: "Invoice Payment Due",
  TaxPreparationFeeText: "Tax Preparation Fee",
};

export const CoverPageConstants = {
  SuccessMessage: {},
  ErrorMessage: {
    clientFetchFailed: "Unable to fetch client Data, Please try again",
    serverError: "Internal Server Error,Please try again",
  },
  InvalidLink:
    "This link is no longer valid. Please contact the company that prepared your tax return.",
};

export const OTPPageConstants = {
  SuccessMessage: {
    OTPGenerateSuccess: "A one-time access code was sent to your email",
  },
  ErrorMessage: {
    lockedByOTP: "Maximum retry reached for one-time access code",
    serverError: "Internal Server Error, Please try to refresh",
    OTPGenerateFailed:
      "Unable to generate one-time access code, Please try again",
    OTPExpired: "Entered one-time access code has Expired",
    OTPError: "Please Enter a Valid one-time access code",
  },
};

export const OTPMobilePageConstants = {
  SuccessMessage: {
    OTPGenerateSuccess:
      "A one-time access code was sent to you via a text message",
  },
  ErrorMessage: {
    lockedByOTP: "Maximum retry reached for one-time access code",
    serverError: "Internal Server Error, Please try to refresh",
    OTPError: "Please Enter a Valid one-time access code",
    OTPGenerateFailed:
      "Unable to generate one-time access code, Please try again",
  },
};

export const SSNPageConstants = {
  SuccessMessage: {},
  ErrorMessage: {
    lockedBySSN: "Maximum retry reached for SSN",
    OTPLocked: "Maximum retry reached for one-time access code",
    InvalidSSN: "Please Enter a Valid one-time access code",
    serverError: "Internal Server Error, Please try to again",
  },
};

export const HeaderConstants = {
  ErrorMessage: {
    RetrieveFailed: "Unable to fetch Details, Please Refresh and try again",
  },
};

export const GroupCoverConstants = {
  ErrorMessage: {
    RetrieveFailed: "Unable to fetch client details",
    ServerError: "Internal Server Error, Please refresh and try again",
  },
};

export const DisplayError = [
  "ERROR_DELETED_RETURN",
  "ERROR_RECALLED_RETURN",
  "ERROR_INVALID_LINK",
  "OTP_LOCKED_MESSAGE",
  "SSN_LOCKED_MESSAGE",
  "SSN_LOCKED",
  "OTP_LOCKED",
  "RETURN_DECLINED_FOR_SIGN",
];

export const AuthToasterError = ["OTP_EXPIRED"];

export const VoucherSelectorPage = {
  TaxpaymentIconPath: "../../../src/assets/images/button_tax_payments.svg",
  EstimatedPaymentIconPath:
    "../../../src/assets/images/button_estimated_payments.svg",
  EstimatedVoucherPageURL: "/VoucherSelect/estimated",
  TaxpaymentVoucherPageURL: "/VoucherSelect/payment",
};

export const VoucherPayConstant = {
  NoPaymentVoucher: "No Payment Voucher Found",
  NoEstimatedVoucher: "No Estimated Voucher Found",
  DocumentStatusFetchFailed: "Unable to fetch Document Status",
  DownloadedDocumentStatusFailed: "Unable to Update Downloaded Document Status",
  UpdatedDocumentAvailable:
    "Document Updated! Download the latest Filing Instructions",
  NoTransmittals: "No Transmittals Found",
};

export const dashboardConstants = {
  UpdatedEstimatedDocument:
    "Estimated tax payments have been revised for this return",
};

export const SIGNATURE_STATUS_OPTIONS_LIST = [
  { label: "E Signed", value: 1 },
  { label: "Manually Signed", value: 2 },
  { label: "Uploaded", value: 3 },
  { label: "Awaiting E-Sign", value: 4 },
  { label: "Awaiting Upload", value: 5 },
  { label: "Signed And E-Signed", value: 8 },
  { label: "Signature Stamping Failed", value: 21 },
  { label: "E-Signed – In Office", value: 22 },
];

export const SIGNATURE_STATUS_OPTIONS_STYLE = {
  "Not Sent": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "73px",
    padding: "1px 8px",
    color: "#B8433C",
    background: "#FAEDEC",
    border: "1px solid #B8433C",
  },
  "E Signed": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "75px",
    padding: "1px 8px",
    color: "#196C39",
    background: "#E9F3ED",
    border: "1px solid #196C39",
    textDecoration: "underline",
    cursor: "pointer",
  },
  "E-Signed – In Office": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "140px",
    padding: "1px 8px",
    color: "#196C39",
    background: "#E9F3ED",
    border: "1px solid #196C39",
    textDecoration: "underline",
    cursor: "pointer",
  },
  "Manually Signed": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "124px",
    padding: "1px 8px",
    color: "#196C39",
    background: "#E9F3ED",
    border: "1px solid #196C39",
    textDecoration: "underline",
    cursor: "pointer",
  },
  Uploaded: {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "79px",
    padding: "1px 8px",
    color: "#196C39",
    background: "#E9F3ED",
    border: "1px solid #196C39",
  },
  "Partially Signed": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "118px",
    padding: "1px 8px",
    color: "#43246C",
    background: "#EEEAF3",
    border: "1px solid #43246C",
    textDecoration: "underline",
    cursor: "pointer",
  },
  "Awaiting E-Sign": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "121px",
    padding: "1px 8px",
    color: "#AB7F2D",
    background: "#FBF5EB",
    border: "1px solid #AB7F2D",
    textDecoration: "underline",
    cursor: "pointer",
  },
  "Awaiting Upload": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "125px",
    padding: "1px 8px",
    color: "#AB7F2D",
    background: "#FBF5EB",
    border: "1px solid #AB7F2D",
    textDecoration: "underline",
    cursor: "pointer",
  },
  "Signed And E-Signed": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "",
    padding: "1px 8px",
    color: "#196C39",
    background: "#E9F3ED",
    border: "1px solid #196C39",
    textDecoration: "underline",
    cursor: "pointer",
  },
  Delegated: {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "83px",
    padding: "1px 8px",
    color: "#AB7F2D",
    background: "#FBF5EB",
    border: "1px solid #AB7F2D",
    textDecoration: "underline",
    cursor: "pointer",
  },
  DelegatedPreview: {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "83px",
    padding: "1px 8px",
    color: "#AB7F2D",
    background: "#FBF5EB",
    border: "1px solid #AB7F2D",
    cursor: "default",
  },
  "Signature Stamping Failed": {
    display: "inline-block",
    fontFamily: "Mulish",
    fontSize: "14px",
    fontWeight: 400,
    lineHeight: "21px",
    borderRadius: "4px",
    height: "23px",
    width: "188px",
    padding: "1px 8px",
    color: "#B8433C",
    background: "#FAEDEC",
    border: "1px solid #B8433C",
  },
};

export const KNOWN_EXCEPTION_MESSAGES = [
  "ResizeObserver loop limit exceeded",
  "ResizeObserver loop completed with undelivered notifications.",
  "ErrorEvent:",
  "ErrorEvent: Script error.",
];
