import { EngagementTypeS, EntityType ,ConsentStatus} from '../../common/Enums';
import { K1EngagementType } from '../../../components/Common/Constants';

export interface IK1ShareHolderDetails {
    id: number;
    shareHolderId: number;
    documentId: number;
    PIN: string;
    name: string;
    email: string;
    address: string;
    k1SendDate: Date;
    count: number;
    consentStatus: ConsentStatus;
    k1DownloadDate: Date;
    entityType: EntityType;
    k1Status: number;
    financialYearEnd: Date;
    createdOn: Date;
    modifiedOn: Date;
    actedBy: string;
    appType: number;
    modelName: number;
    countryCode: string;
    mobileNumber: string;
    isMobileVerify: boolean;
    isSendK1EditEmail: boolean;
    localDate: string;
}


export const initialK1ShareHolderDetails: Readonly<IK1ShareHolderDetails> = {
    id: 0,
    shareHolderId: 0,
    documentId: 0,
    PIN: '',
    name: '',
    email: '',
    address: '',
    k1SendDate: new Date(),
    count: 0,
    consentStatus: ConsentStatus.None,
    k1DownloadDate: new Date(),
    entityType: EntityType.None,
    k1Status: 0,
    financialYearEnd: new Date(),
    createdOn: new Date,
    modifiedOn: new Date,
    actedBy: '',
    appType: 0,
    modelName: 0,
    countryCode: '',
    mobileNumber: '',
    isMobileVerify: false,
    isSendK1EditEmail: false,
    localDate: ''
}

export function GetK1EngagementType(type: EngagementTypeS) {
    switch (type) {
        case EngagementTypeS.E1065:
            return K1EngagementType.E1065;
        case EngagementTypeS.E1120S:
            return K1EngagementType.E1120S;
        case EngagementTypeS.E1041:
            return K1EngagementType.E1041;
        default:
            return '';
    }
}