import * as React from 'react';
import { ClientType, SignatureType } from '../../core/common/Enums';
import { IDialogBox } from '../../core/utilities/ui/DialogBox';
import { container } from '../../startup/inversify.config';
import { TYPES } from '../../startup/types';
import { SiginingConstants, Common, DelegateeSignerConstants } from '../Common/Constants';
import { CustomLargeButton } from '../Common/CustomButton/CustomLargeButton';
import { IMarriedJointTaxReturn, ITaxReturn } from '../../core/domain/models/ITaxReturn';
import { StepLayout } from '../Common/StepLayout/StepLayout';
import { Body } from '../Common/StepLayout/Body';
import { AssignToDelegatee } from '../Delegatee/AssignToDelegatee';
import { IDelegateeSignerDetails } from '../../core/domain/models/IDelegateeSignerModel';
import { SignProcessSteps } from '../../components/Home/TaxpayerSignFlowManager';
import { match } from 'react-router';
import { History } from 'history';
import { logger } from '../../routes';
import SpouseSignRequiredModal from './SpouseSignRequiredModal';
import TaxpayerHelper from '../Helper/TaxpayerHelper';
import { ISignerModel } from '../../core/domain/models/ISignerModel';


interface SelectSignMethodProps {
    onSetSignMethod: (signatureType: SignatureType) => void;
    clearParentState: () => void;
    match?: match;
    history?: History;
    taxReturn?: ITaxReturn;
    saveDelegateeDetails?: (clientId: string,
        delegatee: IDelegateeSignerDetails, callback: () => void) => void;
    isDelegateeAssigned?: boolean;
    goToStep?: (stepNumber: number) => void;
    nextStep?: number;
    saveDelegateeState?: () => void;
    isPreviewMode: boolean;
    checkIfBothSignerDeceased:()=>void;
    signerData?: ISignerModel[],
    updateSpouseMailChange?(clientGuid: string, spouseGuid: string, id: string, mail: string, type: ClientType, step?: boolean, callback?: (step: boolean) => void): void;
    updateSpouseMobileChange?(clientGuid: string, id: string, newMobileNumber: string, newCountryCode: string, ssn: string, type: ClientType, step?: boolean, callback?: (step: boolean) => void): void;
    refreshTaxDocument?: (clientGuid: string) => void;
}

interface SelectSignMethodState {
    showModal: boolean;
    showSpouseSignRequiredModal: boolean;
}


const dialogBox = container.get<IDialogBox>(TYPES.IDialogBox);

export class SelectSignMethod extends React.Component<SelectSignMethodProps, SelectSignMethodState>
{
    private _params: any;

    constructor(props: any) {
        super(props);
        this.state = {
            showModal: false,
            showSpouseSignRequiredModal: false
        }
        this.props.clearParentState();
    }

    componentDidMount() {
        this._params = this.props.match && this.props.match.params;
        logger.trackPageView('Select Sign Method Page', { "ClientId": this._params.clientId, "DocumentId": this.props.taxReturn?.id, "PreviewMode": this.props.isPreviewMode });

        if (this._params.controllerId) {
            this.props.checkIfBothSignerDeceased && this.props.checkIfBothSignerDeceased()
        }

    }

    private selectSignMethodConfirm = (signatureType: SignatureType) => {
        dialogBox.confirm(
            Common.DialogBox.ConfirmationTitle,
            SiginingConstants.StatusMessage.ManualSignSelectConfirmationMessage,
            Common.DialogBox.No,
            Common.DialogBox.Yes,
            (result: boolean) => { result && this.setSignMethod(signatureType) });
    }

    private setSignMethod = (signatureType: SignatureType) => {
        const { onSetSignMethod } = this.props;

        logger.trackTrace(`"${SignatureType[signatureType]}" Sign Method selected`, { "ClientId": this._params?.clientId, "PreviewMode": this.props.isPreviewMode });
        onSetSignMethod(signatureType);
    }

    private openDelegationPopUp = () => {
        let taxReturn = this.props.taxReturn as IMarriedJointTaxReturn;
        if((TaxpayerHelper.isMarried(taxReturn) &&
        ((TaxpayerHelper.isFirstSignerLoggedIn(taxReturn, this.props.signerData, this._params?.clientId) &&
            !TaxpayerHelper.isNextSignerDeceased(taxReturn, this.props.signerData))
        )))
            this.setState({ showSpouseSignRequiredModal: true });
        else
            this.setState({ showModal: true });
    }

    private closeSpouseSignRequiredModal = () => {
        this.setState({ showSpouseSignRequiredModal: false });
        this.setState({ showModal: true });
    }

    private closeDelegationPopUp = () => {
        this.setState({ showModal: false });
    }

    render() {

        return (<StepLayout className='select-sign-method-container1'>
            <Body title={SiginingConstants.SelectSignTitle}
                subTitle={SiginingConstants.SelectSignMessage}
                lg={10} md={12} sm={12} xs={12}
                automationTestId={"11BB28B5-42F3-4571-B567-20700B1740B6"}
            >

                <div className='select-sign-method-container2'>
                    <div className='sign-tab'>
                        <CustomLargeButton
                            icon={Common.Images.ElectronicalSignIcon}
                            onClick={() => { this.setSignMethod(SignatureType.ESign) }}
                            automationTestId={"FAA7FF6F-F7EA-4456-A439-7D6B041F7928"}
                        />
                        <div className="large-button-footer-text" data-test-auto="AB352E1B-AA25-414E-82C4-02700354F333">{SiginingConstants.EsignCaption}</div>
                    </div>

                    <div className='sign-tab'>
                        <CustomLargeButton
                            icon={Common.Images.ManuallySignIcon}
                            onClick={() => { this.selectSignMethodConfirm(SignatureType.ManualSign) }}
                            automationTestId={"DCBE68E2-CFDA-4130-AA64-9E1551E5715F"}
                        />
                        <div className="large-button-footer-text" data-test-auto="896B28DD-80A1-4678-B863-9A5AAA52A5B7">{SiginingConstants.ManuallySignCaption}</div>
                    </div>

                    {
                        this.props.taxReturn?.documentSettings.documentSignatureSetting.enableSignerDeligation && 
                        <div className='sign-tab'>
                            <CustomLargeButton
                                icon={Common.Images.DelegateIcon}
                                onClick={this.openDelegationPopUp}
                                automationTestId={"50a234af-19f2-4afb-8ce9-120cc003599b"}
                            />
                            <div className="large-button-footer-text" data-test-auto="5cb2ebda-82f0-4e43-8583-c7ff7fa9567c">{SiginingConstants.DelegateToSomeOne}</div>
                        </div>
                    }
                </div>

                <SpouseSignRequiredModal
                    show={this.state.showSpouseSignRequiredModal}
                    taxReturn={this.props.taxReturn as IMarriedJointTaxReturn}
                    signerData={this.props.signerData}
                    match={this.props.match}
                    onCancel={() => { this.setState({ showSpouseSignRequiredModal: false }) }}
                    onContinue={this.closeSpouseSignRequiredModal}
                    isPreviewMode={this.props.isPreviewMode}
                    updateSpouseMailChange={this.props.updateSpouseMailChange}
                    updateSpouseMobileChange={this.props.updateSpouseMobileChange}
                    refreshTaxDocument={this.props.refreshTaxDocument}
			    />

                <AssignToDelegatee
                    show={this.state.showModal}
                    onCancel={this.closeDelegationPopUp}
                    param={this._params}
                    history={this.props.history}
                    taxReturn={this.props.taxReturn}
                    saveDelegateeSignerDetails={this.props.saveDelegateeDetails}
                    isDelegateeAssigned={this.props.isDelegateeAssigned}
                    goToStep={this.props.goToStep}
                    nextStep={this.props.nextStep}
                    saveDelegateeState={this.props.saveDelegateeState}
                    successMsg={DelegateeSignerConstants.SuccessMessage.SavedDelegateeDetailsSuccess}
                    isPreview={this.props.isPreviewMode}
                />

            </Body>
        </StepLayout>);

    }
}

export default SelectSignMethod;