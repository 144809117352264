import { ILogoutMessage } from "../../core/domain/models/ILogoutMessage";
import { ISignerModel } from "../../core/domain/models/ISignerModel";
import { History } from "history";
import * as React from "react";
import { BroadcastChannel } from "broadcast-channel";
import { OverlayTrigger, Popover } from "react-bootstrap";
import { match } from "react-router";
import { Link } from "react-router-dom";
import { ClientType, DocumentStatus, Role } from "../../core/common/Enums";
import {
  ClientTypes,
  ITaxClient,
  ITaxpayer,
} from "../../core/domain/models/ITaxClient";
import {
  isIndividual,
  isMutual,
  isPartnership,
  ITaxReturn,
  ToMutual,
  getSpouseClientId,
  getSsnValue,
  getClientId,
  getSpouseSsnValue,
} from "../../core/domain/models/ITaxReturn";
import { IAccessCodeValidation } from "../../core/domain/viewModels/ICommon";
import {
  IHeaderInfoViewModel,
  initialHeaderInfoViewModel,
} from "../../core/domain/viewModels/IHeaderInfoViewModel";
import { IUtilities } from "../../core/utilities/Utilities";
import { container } from "../../startup/inversify.config";
import { TYPES } from "../../startup/types";
import {
  ErrorMessages,
  MyAccountConstants,
  ValidationContants,
} from "../Common/Constants";
import {
  ArrowIcon,
  Home,
  SvgIconAccountHeader,
  SvgIconCalendarHeader,
  SvgIconContactHeader,
  SvgIconRequestScreenShare,
} from "../Common/Icons/SvgIcons";
import { MarsNotifier } from "../Common/Notification/MarsNotifier";
import {
  isValidEmailAddress,
  validateMobileNumber,
  validateRequiredMobileNumber,
} from "../Common/Validations";
import TaxpayerHelper from "../Helper/TaxpayerHelper";
import { AccessCode } from "../Layout/AccessCode";
import { MyAccount } from "../Layout/MyAccount";
import MyDownload, { IDownloadedZipFilesModel } from "../Layout/MyDownload";
import { WebrtcSignalR } from "../ScreenShare/WebrtcSignalR";
import { PathConstants } from "../Common/Constants";
import { ICompany } from "../../core/domain/models/company/Company";
import { logger } from "../../routes";
import { handleKeyDown } from "../Helper/HelperFunction";
import { IClientProcessViewModel } from "../../core/domain/viewModels/IClientProcessViewModel";
import { Guid } from "../../core/utilities/Guid";

const utilities = container.get<IUtilities>(TYPES.IUtilities);
const channel = new BroadcastChannel("ssr-taxpayer");

export interface HeaderProps {
  headerInfo: IHeaderInfoViewModel;
  match: match;
  history: History;
  taxdocument: ITaxReturn;
  signerData: ISignerModel[];
  iceServers: RTCIceServer[];
  getIceServers: (id: string) => void;
  refreshTaxDocument: (clientGuid: string) => void;
  updateEmailAddress(
    clientGuid: string,
    spouseGuid: string,
    id: string,
    mail: string,
    type: ClientType
  ): void;
  updateMobileNumber(
    clientGuid: string,
    id: string,
    number: string,
    countryCodeValue: string,
    ssnValue: string,
    clientType: ClientType,
    callback: () => void
  ): void;
  updateTaxDocument: (taxDocument: ITaxReturn) => void;
  downlistList: IDownloadedZipFilesModel[];
  deleteMyDownloads: (downloadId: string, clientId: string) => void;
  clearAllMyDownloads: (clientId: string) => void;
  downloadK1Zip: (clientId: string, downloadId: string) => void;
  validateTimeBasedAccessCode: (
    accessCode: IAccessCodeValidation,
    callback: () => void
  ) => void;
  logout: (clientGuid: string, callback: (id: string) => void) => void;
  requestMyDownload: (clientId: string) => any;
  isScreenShareEnabled: boolean;
  isPreviewMode: boolean;
  getWebRTCSignalRHubBaseURL: (
    id: string,
    callback: (signalRHubBaseURL: string) => void
  ) => void;
  webRTCSignalRHubBaseURL: string;
  companyData: ICompany;
  clientProcessData: IClientProcessViewModel;
  getClientHubDomainURL: (
    clientGuid: string,
    callback: (hubDomainURL: string) => void
  ) => void;
  checkIfUserExistsInOneHub: (
    clientGuid: string,
    callback: (isUserExists: boolean) => void
  ) => void;
  getOnehubRedirectURL: (
    clientGuid: string,
    callback: (onehubRedirectURL: string) => void
  ) => void;
}

export interface HeaderState {
  headerInfo: IHeaderInfoViewModel;
  showMyAccountPopUp: boolean;
  showAccessCodePopUp: boolean;
  countryCode: string;
  mobileNumber: string;
  spouseEmailAddress: string;
  spouseCountryCode: string;
  spouseMobileNumber: string;
  ssn: string;
  showMyDownloadPopUp: boolean;
  screenShareInProgress: boolean;
  showAccountPopover: boolean;
  showContactPopover: boolean;
  windowWidth: number;
  isUserExistsInOneHub: boolean;
  isAccessedFromOnehub: boolean;
}

export class Header extends React.Component<HeaderProps, HeaderState> {
  webrtcRef: any;
  popoverRef: any;
  contactRef: any;
  inputRef: any;

  constructor(props: HeaderProps, states: HeaderState) {
    super(props, states);
    this.state = {
      headerInfo: initialHeaderInfoViewModel,
      showMyAccountPopUp: false,
      showAccessCodePopUp: false,
      countryCode: "",
      mobileNumber: "",
      spouseEmailAddress: "",
      spouseCountryCode: "",
      spouseMobileNumber: "",
      ssn: "",
      showMyDownloadPopUp: false,
      screenShareInProgress: false,
      showAccountPopover: false,
      showContactPopover: false,
      windowWidth: window.innerWidth,
      isAccessedFromOnehub: false,
      isUserExistsInOneHub: false,
    };

    this.webrtcRef = React.createRef();
    this.popoverRef = React.createRef();
    this.contactRef = React.createRef();
    this.inputRef = React.createRef();
  }

  static getDerivedStateFromProps(
    nextProps: HeaderProps,
    prevState: HeaderState
  ) {
    if (
      nextProps.headerInfo &&
      (nextProps.headerInfo.brandingSettings.coverPageSetting.bgColorCode !==
        prevState.headerInfo.brandingSettings.coverPageSetting.bgColorCode ||
        nextProps.headerInfo.brandingSettings.coverPageSetting.foreColorCode !==
          prevState.headerInfo.brandingSettings.coverPageSetting.foreColorCode)
    ) {
      utilities.applyBrandingSettings(nextProps.headerInfo.brandingSettings);
      window.Variables.companyName = nextProps.headerInfo.companyName;
      return {
        headerInfo: nextProps.headerInfo,
      };
    } else {
      return null;
    }
  }

  componentDidUpdate(
    prevProps: Readonly<HeaderProps>,
    prevState: Readonly<HeaderState>,
    snapshot?: any
  ): void {
    if (
      prevState.isAccessedFromOnehub !==
      this.props.clientProcessData.isAccessedFromOneHub
    ) {
      this.setState({
        isAccessedFromOnehub: this.props.clientProcessData.isAccessedFromOneHub,
      });
    }
  }

  redirectToOneHub = () => {
    let params: any = this.props.match.params;
    this.props.getClientHubDomainURL(
      params.clientId,
      (oneHubDomainURL: string) => {
        window.location.href = oneHubDomainURL;
      }
    );
  };

  redirectToOneHubAuth = () => {
    if (
      this.props.headerInfo.loggedInUserInfo.role.toString() ===
      Role[Role.CPA].toString()
    ) {
      MarsNotifier.Warning(
        ErrorMessages.UTEOnehubNotAccesibleForClientView,
        null
      );
      return;
    } else {
      let params: any = this.props.match.params;
      this.props.getOnehubRedirectURL(
        params.clientId,
        (oneHubRedirectURL: string) => {
          window.location.href = oneHubRedirectURL;
        }
      );
    }
  };

  closeDownloadPopup = () => {
    this.setState({ showMyDownloadPopUp: false });
  };

  openDownloadPopup = () => {
    this.setState({ showMyDownloadPopUp: true });
  };

  componentDidMount() {
    let params: any = this.props.match.params;
    this.props.checkIfUserExistsInOneHub(
      params.clientId,
      (isUserExists: boolean) => {
        this.setState({ isUserExistsInOneHub: isUserExists });
      }
    );
    document.addEventListener("mousedown", this.handleClickOutside);
    document.addEventListener("mousedown", this.handleClickOutsideContact);
    document.addEventListener("keydown", this.handleEscapeKeyEvent);
    if (screen && screen.orientation && screen.orientation.addEventListener) {
      screen.orientation.addEventListener(
        "change",
        this.handleOrientationChange
      );
    }
  }

  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
    document.removeEventListener("mousedown", this.handleClickOutsideContact);
    document.removeEventListener("keydown", this.handleEscapeKeyEvent);
    if (
      screen &&
      screen.orientation &&
      screen.orientation.removeEventListener
    ) {
      screen.orientation.removeEventListener(
        "change",
        this.handleOrientationChange
      );
    }
  }

  handleOrientationChange = () => {
    this.setState({ windowWidth: window.innerWidth });
  };

  handleClickOutside = (event: any) => {
    if (
      this.popoverRef &&
      this.state.showAccountPopover &&
      this.popoverRef.current &&
      !this.popoverRef.current.contains(event.target)
    ) {
      this.setState({ showAccountPopover: false });
    }
  };

  handleClickOutsideContact = (event: any) => {
    if (
      this.contactRef &&
      this.state.showContactPopover &&
      this.contactRef.current &&
      !this.contactRef.current.contains(event.target)
    ) {
      this.setState({ showContactPopover: false });
    }
  };

  handleEscapeKeyEvent = (event: any) => {
    handleKeyDown(
      event,
      () => {
        if (this.state.showAccountPopover) {
          this.setState({ showAccountPopover: false });
        }
        if (this.state.showContactPopover) {
          this.setState({ showContactPopover: false });
        }
      },
      "Escape"
    );
  };

  getMyaccount = () => {
    this.setState({
      showMyAccountPopUp: true,
    });
  };

  screenShareLink = () => {
    this.state.screenShareInProgress
      ? this.endScreenShareSession()
      : this.setState({ showAccessCodePopUp: true });
  };

  onCancelMyAccountPopUp = () => {
    this.setState({
      showMyAccountPopUp: false,
    });
  };

  onCancelAccessCodePopUp = () => {
    this.setState({
      screenShareInProgress: false,
      showAccessCodePopUp: false,
    });
  };

  private getPartnerShip(): ITaxClient | undefined {
    const taxDocument = this.props.taxdocument;
    if (taxDocument && isPartnership(taxDocument)) {
      return taxDocument.partnership;
    }
    return undefined;
  }

  private getTaxPayerInfo(): ITaxpayer | undefined {
    const taxDocument = this.props.taxdocument;
    if (taxDocument && isIndividual(taxDocument)) {
      return taxDocument.taxpayer;
    } else if (isMutual(taxDocument)) {
      if (taxDocument.clientType === ClientType.Controller) {
        let params: any = this.props.match.params;
        if (params.clientId == taxDocument.taxpayer.clientGuid) {
          return taxDocument.taxpayer;
        } else if (params.clientId == taxDocument.spouse.clientGuid) {
          return taxDocument.spouse;
        } else return undefined;
      }
      if (
        taxDocument.documentSettings?.deliverySettings.deliverTo ==
          ClientTypes.Taxpayer &&
        taxDocument.clientType == ClientType.Taxpayer
      ) {
        return taxDocument.taxpayer;
      } else if (
        taxDocument.documentSettings?.deliverySettings.deliverTo ==
          ClientTypes.Taxpayer &&
        taxDocument.clientType == ClientType.Spouse
      ) {
        return taxDocument.spouse;
      } else if (
        taxDocument.documentSettings?.deliverySettings.deliverTo ==
          ClientTypes.Spouse &&
        taxDocument.clientType == ClientType.Spouse
      ) {
        return taxDocument.spouse;
      } else if (
        taxDocument.documentSettings?.deliverySettings.deliverTo ==
          ClientTypes.Spouse &&
        taxDocument.clientType == ClientType.Taxpayer
      ) {
        return taxDocument.taxpayer;
      }
    }
    return undefined;
  }

  private getSpouseInfo(): ITaxpayer | undefined {
    const taxDocument = this.props.taxdocument;
    let params: any = this.props.match.params;
    if (taxDocument && isMutual(taxDocument)) {
      if (
        taxDocument.clientType == ClientType.Taxpayer ||
        (taxDocument.clientType == ClientType.Controller &&
          taxDocument.taxpayer.clientGuid == params.clientId)
      ) {
        if (
          taxDocument.documentSettings.deliverySettings.deliverTo ==
            ClientTypes.Taxpayer ||
          taxDocument.taxpayer.clientGuid == params.clientId
        ) {
          return taxDocument.spouse;
        }
      } else {
        if (
          taxDocument.documentSettings.deliverySettings.deliverTo ==
            ClientTypes.Spouse ||
          (taxDocument.clientType == ClientType.Controller &&
            taxDocument.spouse.clientGuid == params.clientId)
        ) {
          return taxDocument.taxpayer;
        }
      }
    }
    return undefined;
  }

  getClientIdForMutualDocument = (taxDocument: ITaxReturn): number => {
    if (taxDocument.clientType === ClientType.Controller) {
      let params: any = this.props.match.params;
      return getSpouseClientId(taxDocument, params.clientId);
    }
    return taxDocument &&
      taxDocument.clientType == ClientType.Spouse &&
      isMutual(taxDocument)
      ? taxDocument.taxpayer.id
      : taxDocument &&
        taxDocument.clientType == ClientType.Taxpayer &&
        isMutual(taxDocument)
      ? taxDocument.spouse.id
      : 0;
  };

  getSsnForMutualDocument = (taxDocument: ITaxReturn): string => {
    if (taxDocument.clientType === ClientType.Controller) {
      let params: any = this.props.match.params;
      return getSpouseSsnValue(taxDocument, params.clientId);
    }
    return taxDocument &&
      taxDocument.clientType == ClientType.Spouse &&
      isMutual(taxDocument)
      ? taxDocument.taxpayer.ssn
      : taxDocument &&
        taxDocument.clientType == ClientType.Taxpayer &&
        isMutual(taxDocument)
      ? taxDocument.spouse.ssn
      : "";
  };

  getClientId = (taxDocument: ITaxReturn): number => {
    if (taxDocument.clientType === ClientType.Controller) {
      let params: any = this.props.match.params;
      return getClientId(taxDocument, params.clientId);
    }
    return taxDocument &&
      taxDocument.clientType == ClientType.Taxpayer &&
      isIndividual(taxDocument)
      ? taxDocument.taxpayer.id
      : taxDocument &&
        taxDocument.clientType == ClientType.Spouse &&
        isMutual(taxDocument)
      ? taxDocument.spouse.id
      : taxDocument &&
        taxDocument.clientType == ClientType.Taxpayer &&
        isMutual(taxDocument)
      ? taxDocument.taxpayer.id
      : taxDocument &&
        taxDocument.clientType == ClientType.Partnership &&
        isPartnership(taxDocument)
      ? taxDocument.partnership.id
      : 0;
  };

  getSsn = (taxDocument: ITaxReturn): string => {
    if (taxDocument.clientType === ClientType.Controller) {
      let params: any = this.props.match.params;
      return getSsnValue(taxDocument, params.clientId);
    }
    return taxDocument &&
      taxDocument.clientType == ClientType.Taxpayer &&
      isIndividual(taxDocument)
      ? taxDocument.taxpayer.ssn
      : taxDocument &&
        taxDocument.clientType == ClientType.Spouse &&
        isMutual(taxDocument)
      ? taxDocument.spouse.ssn
      : taxDocument &&
        taxDocument.clientType == ClientType.Taxpayer &&
        isMutual(taxDocument)
      ? taxDocument.taxpayer.ssn
      : taxDocument &&
        taxDocument.clientType == ClientType.Partnership &&
        isPartnership(taxDocument)
      ? taxDocument.partnership.ssn
      : "";
  };
  validateAccountDetails = (
    emailAddress: string,
    mobileNumber: string,
    countryCode: string
  ): boolean => {
    let isValid: boolean = true;
    if (emailAddress.trim() === "" || !isValidEmailAddress(emailAddress)) {
      MarsNotifier.Warning(
        MyAccountConstants.WarningMessage.InvalidEmailAddress,
        null
      );
      isValid = false;
    } else if (countryCode) {
      if (!mobileNumber) {
        isValid = false;
        MarsNotifier.Warning(ValidationContants.PhoneNumberWarning, "");
      } else if (!validateMobileNumber(mobileNumber, countryCode)) {
        isValid = false;
      }
    }
    if (mobileNumber) {
      if (!countryCode) {
        isValid = false;
        MarsNotifier.Warning(ValidationContants.CountryCodeWarning, "");
      }
    }
    return isValid;
  };

  private onUpdateDetails = (
    countryCode: string,
    mobileNumber: string,
    spouseEmailAddress: string,
    spouseCountryCode: string,
    spouseMobileNumber: string
  ) => {
    let params: any = this.props.match.params;
    if (
      this.props.headerInfo.loggedInUserInfo.role.toString() ===
      Role[Role.CPA].toString()
    ) {
      this.setState({ showMyAccountPopUp: false, showAccessCodePopUp: false });
      logger.trackTrace(`Mobile Number updated from myAccount`, {
        ClientId: params.clientId,
        PreviewMode:
          this.props.headerInfo.loggedInUserInfo.role.toString() ===
          Role[Role.CPA].toString(),
      });
      return false;
    } else if (this.getSpouseInfo()) {
      if (
        ((!mobileNumber && !countryCode) ||
          validateRequiredMobileNumber(mobileNumber, countryCode)) &&
        this.validateAccountDetails(
          spouseEmailAddress,
          spouseMobileNumber,
          spouseCountryCode
        )
      ) {
        const taxDocument = this.props.taxdocument;
        const taxDocumentClient: any = this.props.match.params;
        const clientId1: number = this.getClientId(taxDocument);
        const ssnValue1: string = this.getSsn(taxDocument);

        const clientId2: number =
          this.getClientIdForMutualDocument(taxDocument);
        const ssnValue2: string = this.getSsnForMutualDocument(taxDocument);

        if (
          (taxDocument.clientType == ClientType.Taxpayer ||
            taxDocument.clientType == ClientType.Controller) &&
          isIndividual(taxDocument)
        ) {
          taxDocument.taxpayer.mobileNumber = mobileNumber;
          taxDocument.taxpayer.countryCode = countryCode;
          this.props.updateTaxDocument(taxDocument);
        } else if (
          (taxDocument.clientType == ClientType.Partnership ||
            taxDocument.clientType == ClientType.Controller) &&
          isPartnership(taxDocument)
        ) {
          taxDocument.partnership.mobileNumber = mobileNumber;
          taxDocument.partnership.countryCode = countryCode;
          this.props.updateTaxDocument(taxDocument);
        } else if (
          isMutual(taxDocument) &&
          (taxDocument.clientType == ClientType.Spouse ||
            (taxDocument.clientType == ClientType.Controller &&
              taxDocument.spouse.clientGuid ==
                (this.props.match.params as any).clientId))
        ) {
          taxDocument.spouse.mobileNumber = mobileNumber;
          taxDocument.spouse.countryCode = countryCode;
          this.props.updateTaxDocument(taxDocument);
        } else if (
          isMutual(taxDocument) &&
          (taxDocument.clientType == ClientType.Taxpayer ||
            (taxDocument.clientType == ClientType.Controller &&
              taxDocument.taxpayer.clientGuid ==
                (this.props.match.params as any).clientId))
        ) {
          taxDocument.taxpayer.mobileNumber = mobileNumber;
          taxDocument.taxpayer.countryCode = countryCode;
        }

        this.setState({ mobileNumber, countryCode }, () => {
          this.props.updateMobileNumber(
            taxDocumentClient.clientId,
            clientId1.toString(),
            this.state.mobileNumber,
            this.state.countryCode,
            ssnValue1,
            taxDocument.clientType,
            () => {}
          );
        });

        if (isMutual(taxDocument)) {
          let params: any = this.props.match.params;
          if (
            taxDocument.clientType == ClientType.Spouse ||
            (taxDocument.clientType == ClientType.Controller &&
              taxDocument.spouse.clientGuid == params.clientId)
          ) {
            taxDocument.taxpayer.email = spouseEmailAddress;
            taxDocument.taxpayer.mobileNumber = spouseMobileNumber;
            taxDocument.taxpayer.countryCode = spouseCountryCode;
            this.props.updateTaxDocument(taxDocument);
          } else if (
            taxDocument.clientType == ClientType.Taxpayer ||
            (taxDocument.clientType == ClientType.Controller &&
              taxDocument.taxpayer.clientGuid == params.clientId)
          ) {
            taxDocument.spouse.email = spouseEmailAddress;
            taxDocument.spouse.mobileNumber = spouseMobileNumber;
            taxDocument.spouse.countryCode = spouseCountryCode;
            this.props.updateTaxDocument(taxDocument);
          }
        }

        this.setState(
          { spouseEmailAddress, spouseMobileNumber, spouseCountryCode },
          () => {
            this.props.updateEmailAddress(
              taxDocumentClient.clientId,
              TaxpayerHelper.getSecondSignerGuid(
                ToMutual(this.props.taxdocument),
                this.props.signerData,
                taxDocumentClient.clientId
              ).toString(),
              TaxpayerHelper.getSecondSignerId(
                ToMutual(this.props.taxdocument),
                this.props.signerData,
                taxDocumentClient.clientId
              ).toString(),
              this.state.spouseEmailAddress,
              TaxpayerHelper.getSecondSignerType(
                ToMutual(this.props.taxdocument),
                this.props.signerData,
                taxDocumentClient.clientId
              )
            );
            this.props.updateMobileNumber(
              taxDocumentClient.clientId,
              clientId2.toString(),
              this.state.spouseMobileNumber,
              this.state.spouseCountryCode,
              ssnValue2,
              taxDocument.clientType,
              () => {
                this.onTaxClientMobileUpdateSuccess(taxDocument.clientType);
                this.setState({ showMyAccountPopUp: false }, () => {
                  logger.trackTrace(`Mobile Number updated from myAccount`, {
                    ClientId: taxDocumentClient.clientId,
                    PreviewMode:
                      this.props.headerInfo.loggedInUserInfo.role.toString() ===
                      Role[Role.CPA].toString(),
                  });
                });
              }
            );
          }
        );
      }
    } else {
      if (
        (!mobileNumber && !countryCode) ||
        validateRequiredMobileNumber(mobileNumber, countryCode)
      ) {
        const taxDocument = this.props.taxdocument;
        const taxDocumentClient: any = this.props.match.params;
        const clientId: number = this.getClientId(taxDocument);
        const ssnValue: string = this.getSsn(taxDocument);

        if (
          (taxDocument.clientType == ClientType.Taxpayer ||
            taxDocument.clientType == ClientType.Controller) &&
          isIndividual(taxDocument)
        ) {
          taxDocument.taxpayer.mobileNumber = mobileNumber;
          taxDocument.taxpayer.countryCode = countryCode;
          this.props.updateTaxDocument(taxDocument);
        } else if (
          (taxDocument.clientType == ClientType.Partnership ||
            taxDocument.clientType == ClientType.Controller) &&
          isPartnership(taxDocument)
        ) {
          taxDocument.partnership.mobileNumber = mobileNumber;
          taxDocument.partnership.countryCode = countryCode;
          this.props.updateTaxDocument(taxDocument);
        } else if (
          isMutual(taxDocument) &&
          (taxDocument.clientType == ClientType.Spouse ||
            (taxDocument.clientType == ClientType.Controller &&
              taxDocument.spouse.clientGuid ==
                (this.props.match.params as any).clientId))
        ) {
          taxDocument.spouse.mobileNumber = mobileNumber;
          taxDocument.spouse.countryCode = countryCode;
          this.props.updateTaxDocument(taxDocument);
        } else if (
          isMutual(taxDocument) &&
          (taxDocument.clientType == ClientType.Taxpayer ||
            (taxDocument.clientType == ClientType.Controller &&
              taxDocument.taxpayer.clientGuid ==
                (this.props.match.params as any).clientId))
        ) {
          taxDocument.taxpayer.mobileNumber = mobileNumber;
          taxDocument.taxpayer.countryCode = countryCode;
        }

        this.setState(
          { mobileNumber: mobileNumber, countryCode: countryCode },
          () => {
            this.props.updateMobileNumber(
              taxDocumentClient.clientId,
              clientId.toString(),
              this.state.mobileNumber,
              this.state.countryCode,
              ssnValue,
              taxDocument.clientType,
              () => {
                this.onTaxClientMobileUpdateSuccess(taxDocument.clientType);
                this.setState({ showMyAccountPopUp: false }, () => {
                  logger.trackTrace(`Mobile Number updated from myAccount`, {
                    ClientId: taxDocumentClient.clientId,
                    PreviewMode:
                      this.props.headerInfo.loggedInUserInfo.role.toString() ===
                      Role[Role.CPA].toString(),
                  });
                });
              }
            );
          }
        );
      }
    }
  };

  onTaxClientMobileUpdateSuccess = (clientType: ClientType) => {
    switch (clientType) {
      /* case ClientType.Taxpayer:
                 MarsNotifier.Success(MyAccountConstants.StatusMessage.EditSpouseDetails, null);
                 break;
             case ClientType.Spouse:
                 MarsNotifier.Success(MyAccountConstants.StatusMessage.EditTaxpayerDetails, null);
                 break;*/
      default:
        MarsNotifier.Success(
          MyAccountConstants.StatusMessage.UpdateInformation,
          null
        );
        break;
    }
  };

  handleValidationSuccessful = () => {
    this.setState({
      showAccessCodePopUp: false,
      screenShareInProgress: true,
    });

    //call some function in the parent component to display that screen sharing is in progress
  };

  showMyDownloads = (): boolean => {
    return (
      this.props.taxdocument.clientType == ClientType.Partnership ||
      (isPartnership(this.props.taxdocument) &&
        this.props.taxdocument.partnership.clientGuid ==
          (this.props.match.params as any).clientId)
    );
  };

  endScreenShareSession = () => {
    this.webrtcRef.onHangup();
    this.setState({ showAccessCodePopUp: true });
  };

  Logout() {
    let param: any = this.props.match.params;

    const message: ILogoutMessage = {
      isLoggedOut: true,
      clientGuid: param.controllerId ? param.controllerId : param.clientId,
    };

    channel
      .postMessage(message)
      .then((value: any) => {
        this.props.logout(
          param.controllerId ? param.controllerId : param.clientId,
          this.onLogOutCompletion
        );
      })
      .catch((error: any) => {
        console.log(error);
      });
  }
  onLogOutCompletion = (id: string) => {
    let param: any = this.props.match.params;
    const url = param.controllerId
      ? PathConstants.ControllerLogin + id
      : PathConstants.ClientLogin + id;
    this.props.history.push(url);
  };

  public render() {
    let param: any = this.props.match.params;
    var isValidControllerId = false;
    if (param && param.controllerId) {
      isValidControllerId = Guid.isValid(param.controllerId);
    }
    let companyLogo = null;
    const toggleMenuWidth =
      this.state.windowWidth > 0
        ? this.inputRef.current?.getBoundingClientRect()?.width
        : 0;
    if (this.state.headerInfo.companyWhiteLogoPath == "") {
      companyLogo = (
        <span
          style={{ marginLeft: `${toggleMenuWidth > 0 ? "30px" : ""}` }}
          className={`company-name ${
            this.props.headerInfo.companyName.length > 50 ? "long-text" : ""
          }`}
        >
          {this.state.headerInfo.companyName}
        </span>
      );
    } else {
      companyLogo = (
        <img
          style={{ marginLeft: `${toggleMenuWidth > 0 ? "30px" : ""}` }}
          className="logo-img"
          src={this.state.headerInfo.companyWhiteLogoPath}
          alt="company-white-logo"
        />
      );
    }

    let contactPersonFullName: string = "";
    const contactPerson = this.state.headerInfo.contactPerson;
    contactPersonFullName += contactPerson.firstName;
    if (contactPerson.middleName && contactPerson.middleName.length > 0) {
      contactPersonFullName += " " + contactPerson.middleName;
    }
    if (contactPerson.lastName && contactPerson.lastName.length > 0) {
      contactPersonFullName += " " + contactPerson.lastName;
    }

    const contactInfoPopover = (
      <Popover id="contactInfoPopover" placement="bottom">
        <div ref={this.contactRef}>
          <h3 className="popover-header">Contact Information</h3>
          <div
            className="popover-body"
            data-test-auto="3464EE21-1DF8-4F1F-BE4B-D838ACE36298"
          >
            <div className="medium">{contactPersonFullName} </div>
            <div>
              <strong>{utilities.formateFax(contactPerson.phoneNumber)}</strong>
              {contactPerson &&
                contactPerson.extension &&
                contactPerson.extension.length > 0 && (
                  <strong> Ext: {contactPerson.extension} </strong>
                )}
            </div>
            <div className="mail">
              <a
                tabIndex={2}
                style={{ color: "#0973BA" }}
                href={"mailto:" + contactPerson.email}
              >
                {contactPerson.email}
              </a>
            </div>
            {this.props.isScreenShareEnabled &&
              this.props.match.url.search("screenshare") < 1 && (
                <div className="screen-share">
                  <Link
                    id="lnkAccessCodePopup"
                    to={"#"}
                    onClick={() => {
                      this.screenShareLink();
                      this.setState({ showContactPopover: false });
                    }}
                    data-test-auto="CD23630C-42E3-4CF7-A9A9-5EB35C5B0C69"
                    tabIndex={3}
                  >
                    <SvgIconRequestScreenShare className="modalIcon" />
                    <span>
                      {this.state.screenShareInProgress
                        ? "Stop Screen Share"
                        : "Share Screen"}
                    </span>
                  </Link>
                </div>
              )}
          </div>
        </div>
      </Popover>
    );

    const accountPopover = (
      <Popover id="accountPopover" placement="bottom-start">
        <div className="popover-body" ref={this.popoverRef}>
          <div className="account-menu">
            <div
              className="profile-name"
              title={this.props.headerInfo.clientName}
            >
              Hi, {this.props.headerInfo.clientName}!
            </div>
            <div
              className="my-account dropdown-item"
              onClick={() => {
                this.getMyaccount();
                this.setState({ showAccountPopover: false });
              }}
              onKeyDown={(e: any) =>
                handleKeyDown(e, () => {
                  this.getMyaccount();
                  this.setState({ showAccountPopover: false });
                })
              }
              data-test-auto="65AD7EA3-7B05-43C9-B862-F079DE711606"
              tabIndex={5}
            >
              Profile
            </div>
            {this.props.companyData?.uteClientHubSettings?.uteEnabled &&
              this.state.isUserExistsInOneHub &&
              !(this.props.taxdocument.groupId > 0) &&
              !this.state.isAccessedFromOnehub &&
              !this.props.clientProcessData.isInOfficeESign &&
              (this.props.taxdocument.documentStatus.toString() ===
                DocumentStatus[DocumentStatus.USERSIGNED] ||
                this.props.taxdocument.documentStatus.toString() ===
                  DocumentStatus[DocumentStatus.DELIVERED] ||
                this.props.taxdocument.documentStatus.toString() ===
                  DocumentStatus[DocumentStatus.UPLOADED]) &&
              !(param.controllerId != null && param.controllerId != "") && (
                <span
                  className="access-ute-clienthub dropdown-item"
                  onClick={() => {
                    this.redirectToOneHubAuth();
                  }}
                  data-test-auto="D24BF8D0-A2D3-4BB9-8F91-59AF2E7FD03F"
                  tabIndex={6}
                >
                  Access Client Portal
                </span>
              )}
            {this.showMyDownloads() && (
              <span
                className="my-downloads dropdown-item"
                onClick={() => {
                  this.openDownloadPopup();
                  this.setState({ showAccountPopover: false });
                }}
                onKeyDown={(e: any) =>
                  handleKeyDown(e, () => {
                    this.openDownloadPopup();
                    this.setState({ showAccountPopover: false });
                  })
                }
                data-test-auto="4EF9FEA8-C450-4800-BE40-BB9A7D2C4AC3"
                tabIndex={6}
              >
                My Downloads
              </span>
            )}
            {this.props.headerInfo &&
              this.props.headerInfo.loggedInUserInfo.role.toString() !==
                Role[Role.CPA].toString() && (
                <>
                  <hr className="dropdown-divider" />
                  <div
                    className="log-out dropdown-item"
                    onClick={() => this.Logout()}
                    onKeyDown={(e: any) =>
                      handleKeyDown(e, () => this.Logout())
                    }
                    data-test-auto="767609CB-7931-49FF-A431-CBF72F015D70"
                    tabIndex={7}
                  >
                    Log Out
                  </div>
                </>
              )}
          </div>
        </div>
      </Popover>
    );

    return (
      <header className="app-header">
        <div
          className="header-left-container"
          data-test-auto="951602DF-76D9-480A-BA0F-D12E216FBB2B"
        >
          <input
            type="checkbox"
            className="openSidebarMenu"
            id="openSidebarMenu"
            aria-label="Toggle Side Menu"
          />
          <label
            ref={this.inputRef}
            htmlFor="openSidebarMenu"
            className="sidebarIconToggle"
            aria-label="Side Menu Icon"
          >
            <div className="spinner diagonal part-1"></div>
            <div className="spinner horizontal"></div>
            <div className="spinner diagonal part-2"></div>
          </label>
          <div id="sidebarMenu">
            <ul className="sidebar navbar-nav">
              <li
                className="nav-item layout-dropdown mobile-header-taxYear show"
                data-test-auto="1D9B4F96-A523-4A04-AA58-AB62A9B1DD3A"
              >
                <span
                  className="nav-link layout-dropdown-toggle"
                  role="button"
                  data-toggle="layout-dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="pageslayout-dropdown"
                >
                  <i className="fa fa-calendar margin-right-10"></i>
                  <span>Tax Year</span>
                </span>
                <div
                  className="layout-dropdown-menu show"
                  aria-labelledby="pageslayout-dropdown"
                >
                  <a className="layout-dropdown-item active">
                    {this.state.headerInfo.taxYear}
                  </a>
                </div>
              </li>
              <li
                className="nav-item layout-dropdown show"
                data-test-auto="9E3149F1-20E9-48E8-9067-5E70360C410F"
              >
                <span
                  className="nav-link layout-dropdown-toggle"
                  role="button"
                  data-toggle="layout-dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                  id="pageslayout-account"
                >
                  <i className="fa fa-cog margin-right-10"></i>
                  <span>Account</span>
                </span>
                <div
                  className="layout-dropdown-menu show"
                  aria-labelledby="pageslayout-account"
                >
                  <a
                    className="layout-dropdown-item"
                    onClick={() => this.getMyaccount()}
                    data-test-auto="05558ACE-66E2-42FA-A41F-FF6E8AEC1881"
                  >
                    <i className="fa fa-user ddl-icon headerForeColor"></i>My
                    Account
                  </a>
                  <a
                    className="layout-dropdown-item"
                    onClick={() => this.Logout()}
                    data-test-auto="A213D79D-034B-4264-8249-C1D438D433B3"
                  >
                    <i className="fa fa-sign-out ddl-icon headerForeColor"></i>
                    Logout
                  </a>
                </div>
              </li>
            </ul>
          </div>

          {companyLogo}
        </div>
        <div
          className="header-right-container"
          data-test-auto="710F957F-072E-4A27-867F-0B40C2077D3B"
        >
          {param &&
            param.controllerId &&
            param.controllerId !== "manual" &&
            isValidControllerId &&
            !this.props.isPreviewMode && (
              <span className="header-back-to-controller">
                <i
                  className="fa fa-arrow-circle-left"
                  tabIndex={8}
                  onClick={() => {
                    window.location.href = `${window.location.origin}/Controller/${param.controllerId}`;
                  }}
                  onKeyDown={(e: any) =>
                    handleKeyDown(e, () => {
                      window.location.href = `${window.location.origin}/Controller/${param.controllerId}`;
                    })
                  }
                  title="Go to Controller Dashboard"
                ></i>
              </span>
            )}

          <span className="header-account">
            <OverlayTrigger
              data-test-auto="D8169D39-B5BF-4166-B3E7-DA97E9F7AECB"
              rootClose
              trigger="click"
              onEnter={() => document.body?.click()}
              overlay={accountPopover}
              placement="bottom"
              show={this.state.showAccountPopover}
            >
              <a
                tabIndex={4}
                onClick={() =>
                  this.setState({
                    showAccountPopover: !this.state.showAccountPopover,
                  })
                }
                onKeyDown={(e: any) =>
                  handleKeyDown(e, () =>
                    this.setState({
                      showAccountPopover: !this.state.showAccountPopover,
                    })
                  )
                }
              >
                <span style={{ fontWeight: "700" }}>
                  {this.state.headerInfo.clientName}
                </span>
                <ArrowIcon fillColor="var(--headerForeColor)" />
              </a>
            </OverlayTrigger>
          </span>

          <span
            className="header-contact-info"
            title="Contact Person's Information"
          >
            <OverlayTrigger
              data-test-auto="CB7E32C5-518D-40B5-8E8F-E75C9A89CF58"
              rootClose
              trigger="click"
              onEnter={() => document.body?.click()}
              overlay={contactInfoPopover}
              placement="bottom"
              show={this.state.showContactPopover}
            >
              <a
                tabIndex={1}
                onClick={() =>
                  this.setState({
                    showContactPopover: !this.state.showContactPopover,
                  })
                }
                onKeyDown={(e: any) =>
                  handleKeyDown(e, () =>
                    this.setState({
                      showContactPopover: !this.state.showContactPopover,
                    })
                  )
                }
              >
                <SvgIconContactHeader fillColor="var(--headerForeColor)" />
                <span>Contact</span>
              </a>
            </OverlayTrigger>
          </span>

          {this.props.clientProcessData.isAccessedFromOneHub && (
            <span className="header-home-info" title="Home">
              <a tabIndex={1} onClick={() => this.redirectToOneHub()}>
                <Home fillColor="var(--headerForeColor)" />
                <span>Home</span>
              </a>
            </span>
          )}
          {this.state.headerInfo.taxYear > 0 && (
            <span className="header-taxYear">
              <a data-test-auto="7948215D-22E7-431D-B478-43E85FF56A63">
                <SvgIconCalendarHeader fillColor="var(--headerForeColor)" />
                <span>Tax year: {this.state.headerInfo.taxYear}</span>
              </a>
            </span>
          )}
          <MyDownload
            match={this.props.match}
            showState={this.state.showMyDownloadPopUp}
            onHide={this.closeDownloadPopup}
            downloadsList={this.props.downlistList}
            deleteMyDownloads={this.props.deleteMyDownloads}
            clearAllMyDownloads={this.props.clearAllMyDownloads}
            downloadK1Zip={this.props.downloadK1Zip}
          />
          {this.state.showMyAccountPopUp && (
            <MyAccount
              key="value"
              onHide={this.onCancelMyAccountPopUp}
              showState={this.state.showMyAccountPopUp}
              onSaveButtonClick={this.onUpdateDetails}
              taxDocument={this.props.taxdocument}
              partnerShip={this.getPartnerShip()}
              taxPayer={this.getTaxPayerInfo()}
              spouse={this.getSpouseInfo()}
              loggedInUserClientGuid={(this.props.match.params as any).clientId}
              isTPMfaEnabled={this.props.companyData.isTPMfaEnabled}
              onChangeNumberClick={() => {}}
            />
          )}
          {this.state.showAccessCodePopUp && (
            <AccessCode
              key="value"
              onHide={this.onCancelAccessCodePopUp}
              showState={this.state.showAccessCodePopUp}
              onScreenSharingStart={this.props.validateTimeBasedAccessCode}
              onValidationSuccessful={this.handleValidationSuccessful}
              inProgress={this.state.screenShareInProgress}
              taxDocument={this.props.taxdocument}
              match={this.props.match}
              isPreviewMode={this.props.isPreviewMode}
            />
          )}
          {this.state.screenShareInProgress && (
            //load a signalr component for webrtc
            <WebrtcSignalR
              ref={(ref) => (this.webrtcRef = ref)}
              isDebugEnabled={false}
              match={this.props.match}
              isCPAMode={false}
              handleRemoveStream={() => {
                this.setState({ showAccessCodePopUp: true });
              }}
              getIceServers={this.props.getIceServers}
              iceServers={this.props.iceServers}
              getWebRTCSignalRHubBaseURL={this.props.getWebRTCSignalRHubBaseURL}
              webRTCSignalRHubBaseURL={this.props.webRTCSignalRHubBaseURL}
            />
          )}
        </div>
      </header>
    );
  }
}
